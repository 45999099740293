import {createModel} from "@rematch/core";
import {commonReducers} from "core/store/helpers";
import {apiRequest} from "core/services/api";
import {ApiClientMethod} from "core/utils/api-client";
import {Parent, ParentType} from "common/models/parent";

export interface ParentStore {
  error: string;
  loading: number;
  parent: Parent | null;
}

const initialState: ParentStore = {
  error: "",
  loading: 0,
  parent: null,
};

export const parentStore = createModel({
  state: initialState,

  reducers: {
    ...commonReducers,
    setParent: (state: ParentStore, payload: Parent | null) => ({
      ...state,
      parent: payload,
    }),
  },

  effects: () => ({
    getParent(payload: {type: ParentType; id: string}) {
      this.setError("");
      apiRequest({
        method: ApiClientMethod.GET,
        url: `v2/parents/${encodeURIComponent(payload.id)}`,
        query: {type: payload.type},
        onLoadStart: this.incrementLoading,
        onLoadEnd: this.decrementLoading,
        onSuccess: this.setParent,
        onError: this.setError,
      });
    },
  }),
});
