import * as Cookie from "js-cookie";

const FOSSABOT_SESSION_COOKIE = "sessionv2";

export class UserSession {
  private token: string | null;

  public constructor() {
    this.token = Cookie.get(FOSSABOT_SESSION_COOKIE) || null;
  }

  public deleteToken() {
    Cookie.remove(FOSSABOT_SESSION_COOKIE);
  }

  public hasToken(): boolean {
    return !!this.token;
  }

  public getToken(): string | null {
    return this.token;
  }
}

export const userSession = new UserSession();
