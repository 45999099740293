import {Container, makeStyles, Theme} from "@material-ui/core";
import * as React from "react";

interface Props {
  children: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.grey[900],
    textAlign: "center",
  },
}));

export const MainHeroWrapper: React.FunctionComponent<Props> = (
  props: Props,
) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Container>
        <>{props.children}</>
      </Container>
    </div>
  );
};
