import {app} from "core/app";
import {AppTheme} from "common/models";

const THEME_STORAGE_KEY = "fossabotv2/theme";

export function getTheme() {
  return app.storage.get(THEME_STORAGE_KEY, AppTheme.Light);
}

export function saveTheme(theme: AppTheme) {
  return app.storage.set(THEME_STORAGE_KEY, theme);
}
