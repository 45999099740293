import {
  Container,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import HomeStreamerTiles from "assets/images/home-streamer-tiles.png";
import * as cx from "classnames";
import * as React from "react";
import {useImageLoaded} from "web/common/hooks/use-image-loaded";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    backgroundColor: theme.palette.grey[800],
    position: "relative",
    zIndex: 1,
  },
  containerBackground: {
    width: "100%",
    height: "100%",
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 2,
    backgroundRepeat: "repeat",
    opacity: 0,
  },
  containerBackgroundLoaded: {
    animation:
      "$backgroundCarousel 45s infinite linear, $fadeIn 1s ease-in-out forwards",
    backgroundImage: `url(${HomeStreamerTiles})`,
  },
  containerCover: {
    width: "100%",
    height: "100%",
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 3,
    backgroundColor: theme.palette.primary.main,
    opacity: 0.95,
  },
  content: {
    position: "relative",
    zIndex: 4,
    color: theme.palette.common.white,
  },
  list: {
    alignItems: "center",
    display: "flex",
    flexFlow: "row wrap",
    minHeight: "112px",
    justifyContent: "center",
  },
  title: {
    fontWeight: 700,
    marginBottom: theme.spacing(2),
  },
  textContent: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  inner: {
    maxWidth: "640px",
    width: "100%",
  },
  "@keyframes backgroundCarousel": {
    from: {
      backgroundPosition: "0px 0",
    },
    to: {
      backgroundPosition: "-650px 0",
    },
  },
  "@keyframes fadeIn": {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
}));

export const HomeTrustedStreamers: React.FunctionComponent = () => {
  const classes = useStyles();
  const bgLoaded = useImageLoaded(HomeStreamerTiles);

  return (
    <div className={classes.container}>
      <Container className={classes.content}>
        <div className={classes.inner}>
          <Typography variant="h4" className={classes.title}>
            Trusted by the biggest stars.
          </Typography>
          <Typography variant="body1" className={classes.textContent}>
            Fossabot is already trusted by the biggest stars on
            Twitch. You can focus on running your stream and having a
            good time knowing Fossabot has your back in chat.
          </Typography>
        </div>
      </Container>
      <div className={classes.containerCover} />
      <div
        className={cx(
          classes.containerBackground,
          bgLoaded && classes.containerBackgroundLoaded,
        )}
      />
    </div>
  );
};
