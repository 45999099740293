import * as React from "react";
import * as classNames from "classnames";
import {
  Grid,
  makeStyles,
  Theme,
  Typography,
  Container,
  Hidden,
} from "@material-ui/core";
import {BrandLogo} from "web/common/components/brand-logo";
import {DiscordIcon} from "web/common/components/brands/discord-icon";
import {TwitterBird} from "web/common/components/brands/twitter-bird";
import {TwitchGlitch} from "web/common/components/brands/twitch-glitch";
import {PatreonIcon} from "web/common/components/brands/patreon-icon";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    zIndex: theme.zIndex.appBar,
    backgroundColor: theme.palette.grey[900],
  },
  nav: {
    padding: 0,
    marginLeft: theme.spacing(6),
    display: "flex",
  },
  navItem: {
    listStyle: "none",
    display: "flex",
  },
  item: {
    display: "block",
    color: theme.palette.common.white,
    opacity: 0.8,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    "&:hover": {
      opacity: 1,
    },
  },
  socialItem: {
    opacity: 0.6,
    paddingLeft: theme.spacing(1.25),
    paddingRight: theme.spacing(1.25),
  },
  itemText: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
  },
  navLeft: {
    display: "flex",
    minHeight: "53px",
  },
}));

interface NavItem {
  to: string;
  label: string;
}

const topNav: NavItem[] = [
  {to: "#features", label: "Features"},
  {to: "/docs", label: "Documentation"},
];

interface Props {
  withoutNav?: boolean;
}

interface SocialNavItem {
  to: string;
  label: string;
  icon: React.ComponentType<{height?: string; fill?: string}>;
}

const socialItems: SocialNavItem[] = [
  {
    to: "https://fossabot.com/discord",
    label: "Discord",
    icon: DiscordIcon,
  },
  {
    to: "https://twitter.com/Fossabot",
    label: "Twitter",
    icon: TwitterBird,
  },
  {
    to: "https://twitch.tv/Fossabot",
    label: "Twitch",
    icon: TwitchGlitch,
  },
  {
    to: "https://patreon.com/AidenWallis",
    label: "Patreon",
    icon: PatreonIcon,
  },
];

export const MainNav: React.FunctionComponent<Props> = (
  props: Props,
) => {
  const classes = useStyles();
  return (
    <nav className={classes.wrapper}>
      <Container>
        <Grid container justify="space-between">
          <Grid item xs className={classes.navLeft}>
            <Grid container alignItems="center">
              <Grid item>
                <Link to="/">
                  <BrandLogo />
                </Link>
              </Grid>
              <Hidden smDown implementation="css">
                <Grid item xs>
                  {!props.withoutNav && (
                    <ul className={classes.nav}>
                      {topNav.map((item) => (
                        <li key={item.to} className={classes.navItem}>
                          <a href={item.to} className={classes.item}>
                            <Typography
                              component="span"
                              className={classes.itemText}
                            >
                              {item.label}
                            </Typography>
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
          {!props.withoutNav && (
            <Grid item>
              <Hidden smDown implementation="css">
                <ul className={classes.nav}>
                  {socialItems.map((item: SocialNavItem) => {
                    const IconComponent = item.icon;
                    return (
                      <li key={item.to} className={classes.navItem}>
                        <a
                          href={item.to}
                          className={classNames(
                            classes.item,
                            classes.socialItem,
                          )}
                          target="_blank"
                          rel="noopener noreferrer"
                          title={item.label}
                        >
                          <span aria-hidden="true">
                            <IconComponent
                              height="20px"
                              fill="#fff"
                            />
                          </span>
                          <Typography
                            component="span"
                            variant="srOnly"
                          >
                            {item.label}
                          </Typography>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </Hidden>
            </Grid>
          )}
        </Grid>
      </Container>
    </nav>
  );
};
