import {RoleScope} from "common/models";
import {useParentScopes} from "web/dashboard/contexts";

export function useScopeValidate(scope: RoleScope | RoleScope[]) {
  const scopeSet = useParentScopes();
  if (scopeSet.has("*")) return true;
  if (!Array.isArray(scope)) return scopeSet.has(scope);
  if (scope.length === 0) return true;
  for (let i = 0; i < scope.length; ++i) {
    if (scopeSet.has(scope[i])) return true;
  }
  return false;
}
