import {createModel} from "@rematch/core";
import {UserManagingChannel, TwitchUser} from "common/models";
import {commonReducers} from "core/store/helpers";
import {apiRequest} from "core/services/api";
import {ApiClientMethod} from "core/utils/api-client";
import {twitchUserCache} from "core/utils/twitch-user-cache";

export interface ManagerStore {
  error: string;
  loading: number;
  managingChannels: UserManagingChannel[];
}

const initialState: ManagerStore = {
  error: "",
  managingChannels: [],
  loading: 0,
};

export const managerStore = createModel({
  state: initialState,

  reducers: {
    ...commonReducers,

    setManagingChannels: (
      state: ManagerStore,
      payload: UserManagingChannel[],
    ) => ({
      ...state,
      managingChannels: payload,
    }),
  },

  effects: () => ({
    getManagingChannels() {
      this.setError("");
      apiRequest({
        method: ApiClientMethod.GET,
        url: "v2/user/channels",
        onLoadStart: this.incrementLoading,
        onLoadEnd: this.decrementLoading,
        onError: this.setError,
        onSuccess: (body: {data: UserManagingChannel[]}) => {
          twitchUserCache
            .getIDs(body.data.map((x) => x.provider_id))
            .then((users: Map<string, TwitchUser>) => {
              for (let i = 0; i < body.data.length; ++i) {
                const role = body.data[i];
                const twitchUser = users.get(role.provider_id);
                if (twitchUser) {
                  role.avatar = twitchUser.profile_image_url;
                  role.display_name = twitchUser.display_name;
                  role.login = twitchUser.login;
                  body.data[i] = role;
                }
              }
              this.setManagingChannels(body.data);
            })
            .catch((error) => this.setError(error.toString()));
        },
      });
    },
  }),
});
