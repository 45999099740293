import * as React from "react";
import {BrowserRouter} from "react-router-dom";
import {app} from "./core/app/app";
import {getTheme} from "./core/utils/theme";

import {AppRoot} from "./web/components/app-root";
import {Router} from "./router";
import {SnackbarTransporter} from "web/components/snackbar-transporter";

app.init();
app.store.dispatch.userSettingsStore.updateTheme(getTheme());

const App: React.FunctionComponent = () => (
  <AppRoot>
    <SnackbarTransporter />
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  </AppRoot>
);

app.mount(<App />, document.getElementById("root")!);
