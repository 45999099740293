import * as React from "react";
import * as cx from "classnames";
import {makeStyles, CircularProgress, Theme} from "@material-ui/core";

export enum CenterProgressMargin {
  Small = "small",
  Normal = "normal",
  Medium = "medium",
  Large = "large",
}

interface Props {
  margin?: CenterProgressMargin;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    textAlign: "center",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  content: {
    display: "inline-block",
    margin: "0 auto",
  },
  [CenterProgressMargin.Large]: {
    paddingTop: theme.spacing(24),
    paddingBottom: theme.spacing(24),
  },
  [CenterProgressMargin.Medium]: {
    paddingTop: theme.spacing(16),
    paddingBottom: theme.spacing(16),
  },
  [CenterProgressMargin.Normal]: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  [CenterProgressMargin.Small]: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

export const CenterProgress: React.FunctionComponent<Props> = (
  props: Props,
) => {
  const classes = useStyles();

  return (
    <div
      className={cx(
        classes.container,
        classes[props.margin || CenterProgressMargin.Normal],
      )}
    >
      <div className={classes.content}>
        <CircularProgress />
      </div>
    </div>
  );
};
