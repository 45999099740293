import * as React from "react";
import {makeStyles, Theme, Typography} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    color: theme.palette.grey[900],
    fontWeight: 700,
    marginBottom: theme.spacing(3),
  },
}));

interface Props {
  title: string;
  center?: boolean;
  description: string;
}

export const HomeSectionIntro: React.FunctionComponent<Props> = (
  props: Props,
) => {
  const classes = useStyles();

  return (
    <>
      <Typography
        variant="h4"
        align={props.center ? "center" : undefined}
        className={classes.title}
      >
        {props.title}
      </Typography>
      <Typography
        paragraph
        variant="body1"
        align={props.center ? "center" : undefined}
      >
        {props.description}
      </Typography>
    </>
  );
};
