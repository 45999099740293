import * as React from "react";
import {
  makeStyles,
  Theme,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import {BrandLogo} from "web/common/components/brand-logo";
import {WebFooterNav} from "../web-footer-nav";
import {WebFooterNavItem} from "../web-footer-nav-item";

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    backgroundColor: theme.palette.grey[900],
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  logoCaption: {
    lineHeight: 2,
    color: theme.palette.grey[400],
  },
  logoWrapper: {
    position: "relative",
    display: "inline-block",
    marginBottom: theme.spacing(3),
  },
}));

export const WebFooter: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container>
        <Grid container spacing={4}>
          <Grid item lg={5} xs={12}>
            <div className={classes.logoWrapper}>
              <BrandLogo />
            </div>
            <Typography
              variant="subtitle2"
              className={classes.logoCaption}
            >
              &copy; {new Date().getFullYear()} FossaDev Ltd. All
              rights reserved.
              <br />
              Registered in England and Wales (No. 12219499).
            </Typography>
          </Grid>
          <Grid item lg={3} md={6} xs={12}>
            <WebFooterNav title="Stay Connected">
              <WebFooterNavItem
                linkTo="https://twitch.tv/Fossabot"
                label="Twitch"
              />
              <WebFooterNavItem
                linkTo="https://fossabot.com/discord"
                label="Discord"
              />
              <WebFooterNavItem
                linkTo="https://twitter.com/Fossabot"
                label="Twitter"
              />
            </WebFooterNav>
          </Grid>
          <Grid item lg={3} md={6} xs={12}>
            <WebFooterNav title="More">
              <WebFooterNavItem
                linkTo="https://patreon.com/AidenWallis"
                label="Support on Patreon"
              />
              <WebFooterNavItem
                linkTo="/legal/terms"
                label="Terms of Service"
              />
              <WebFooterNavItem
                linkTo="/legal/privacy"
                label="Privacy Policy"
              />
            </WebFooterNav>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};
