import * as React from "react";
import {
  makeStyles,
  Theme,
  Container,
  Grid,
  SvgIconProps,
} from "@material-ui/core";
import {
  Assignment,
  AttachMoney,
  Block,
  Dashboard,
  Dns,
  Extension,
  Memory,
  PriorityHigh,
  Timer,
} from "@material-ui/icons";
import {HomeSectionIntro} from "../home-section-intro";
import {HomeFeatureItem} from "../home-feature-item";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(15),
    backgroundColor: theme.palette.background.paper,
  },
  grid: {
    marginTop: theme.spacing(12),
    width: "100%",
  },
  gridItem: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

interface HomeFeature {
  icon: React.ComponentType<SvgIconProps>;
  label: string;
  description: string;
}

const features: HomeFeature[] = [
  {
    icon: PriorityHigh,
    label: "Commands",
    description: `Provide dynamic responses to questions and promote your social
      links with versatile, customizable commands.`,
  },
  {
    icon: Timer,
    label: "Timers",
    description: `Post automated messages on a set interval you define - share
      promotional links, relay import messages.`,
  },
  {
    icon: Dns,
    label: "Cloud hosted",
    description: `No download, servers, or technical knowledge required. Have the
      piece of mind Fossabot is always ready to go.`,
  },
  {
    icon: Assignment,
    label: "Audit logs",
    description: `Give yourself and your moderators full transparency and insight to
      any changes on your Fossabot.`,
  },
  {
    icon: Block,
    label: "Spam filters",
    description: `From caps, repetition, symbols to multi-message spam and even malware
      links. We have you covered.`,
  },
  {
    icon: Memory,
    label: "Modules",
    description: `Over 10 easily configurable bot modules that bring engaging and
      exciting features to your chat.`,
  },
  {
    icon: AttachMoney,
    label: "Loyalty",
    description: `Enjoy premier loyalty features that give your community a sense of meaning
      and a place for your viewers to call home.`,
  },
  {
    icon: Extension,
    label: "Integrations",
    description: `Integrate Fossabot with all of your favorite services. Including Discord,
      StreamElements and Streamlabs.`,
  },
  {
    icon: Dashboard,
    label: "Widgets",
    description: `Enjoy small, easy-to-integrate widgets on your stream that encourage your
      chat to get involved and stick around.`,
  },
];

export const HomeFeatures: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Container>
        <Container maxWidth="md">
          <HomeSectionIntro
            center
            title="Unrivalled features, completely free."
            description={`
            Fossabot gives you the tools to build and manage your community
            with a level of customization that isn't possible anywhere else.
            Gain access to the most robust, reliable platform that boasts
            an exceptional uptime record, while providing performance that's
            unmatched anywhere else.
          `}
          />
        </Container>
        <Grid container spacing={6} className={classes.grid}>
          {features.map((feature: HomeFeature) => (
            <Grid item lg={4} md={6} xs={12} key={feature.label}>
              <div className={classes.gridItem}>
                <HomeFeatureItem
                  icon={feature.icon}
                  description={feature.description}
                  label={feature.label}
                />
              </div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};
