import {Modules, ModuleType, AnyModule} from "common/models";
import {createModel} from "@rematch/core";
import {commonReducers} from "core/store/helpers";
import {apiRequest} from "core/services/api";
import {ApiClientMethod} from "core/utils/api-client";
import {app} from "core/app";

export interface ModulesStore {
  error: string;
  loading: number;
  modules: Modules | null;
  loadingModules: Record<ModuleType, boolean>;
}

const initialState: ModulesStore = {
  error: "",
  loading: 0,
  modules: null,
  loadingModules: {
    [ModuleType.Bingo]: false,
    [ModuleType.Duel]: false,
    [ModuleType.EightBall]: false,
    [ModuleType.EmoteCombo]: false,
    [ModuleType.EmotePyramid]: false,
    [ModuleType.Raffle]: false,
    [ModuleType.Roulette]: false,
    [ModuleType.SlotMachine]: false,
    [ModuleType.SongRequest]: false,
  },
};

export const modulesStore = createModel({
  state: initialState,

  reducers: {
    ...commonReducers,

    setModules: (state: ModulesStore, payload: Modules) => ({
      ...state,
      modules: payload,
    }),

    setModuleLoading: (
      state: ModulesStore,
      payload: {type: ModuleType; loading: boolean},
    ) => ({
      ...state,
      loadingModules: {
        ...state.loadingModules,
        [payload.type]: payload.loading,
      },
    }),

    setToggleModule: (
      state: ModulesStore,
      payload: {type: ModuleType; enabled: boolean},
    ) => ({
      ...state,
      modules: {
        ...state.modules,
        [payload.type]: {
          ...state.modules![payload.type],
          enabled: payload.enabled,
        },
      },
    }),
  },

  effects: () => ({
    getModules(parent: string) {
      this.setError("");
      apiRequest({
        method: ApiClientMethod.GET,
        url: `v2/modules/${encodeURIComponent(parent)}`,
        onLoadStart: this.incrementLoading,
        onLoadEnd: this.decrementLoading,
        onError: this.setError,
        onSuccess: this.setModules,
      });
    },

    toggleModule(payload: {
      parent: string;
      type: ModuleType;
      enabled: boolean;
    }) {
      this.setToggleModule({
        type: payload.type,
        enabled: payload.enabled,
      });
      apiRequest({
        method: ApiClientMethod.PATCH,
        url: `v2/modules/${encodeURIComponent(
          payload.parent,
        )}/toggle`,
        body: JSON.stringify({
          module: payload.type,
          enabled: payload.enabled,
        }),
        onError: (error) => {
          this.setToggleModule({
            type: payload.type,
            enabled: !payload.enabled,
          });
          app.snackbarUtil.enqueueSnackbar(error.toString());
        },
      });
    },

    updateModule(payload: {
      parent: string;
      type: ModuleType;
      module: AnyModule;
    }) {
      apiRequest({
        method: ApiClientMethod.PATCH,
        url: `v2/modules/${encodeURIComponent(
          payload.parent,
        )}/${encodeURIComponent(payload.type)}`,
        body: JSON.stringify(payload.module),
        onLoadStart: () =>
          this.setModuleLoading({type: payload.type, loading: true}),
        onLoadEnd: () =>
          this.setModuleLoading({type: payload.type, loading: false}),
        onError: (error) =>
          app.snackbarUtil.enqueueSnackbar(
            `Failed to update module: ${error.toString()}`,
          ),
      });
    },
  }),
});
