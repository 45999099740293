import {
  Avatar,
  ButtonBase,
  CircularProgress,
  LinearProgress,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import {AppTheme, Channel} from "common/models";
import * as React from "react";
import {useLocation} from "react-router-dom";
import {Loadable} from "web/common/components/loadable";
import {DashboardManagingModalProps} from "web/dashboard/components/dashboard-managing-modal/components/dashboard-managing-modal/types";

export interface ReduxStateProps {
  channel: Channel | null;
  loading: number;
}

export interface PublicProps {}

type Props = ReduxStateProps & PublicProps;

const DashboardManagingModal = Loadable<DashboardManagingModalProps>(
  () =>
    import(
      /* webpackChunkName: "dashboard.components.dashboard-managing-modal" */ "../../../dashboard-managing-modal"
    ),
  "DashboardManagingModal",
);

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: "relative",
    paddingTop: theme.spacing(0.5),
  },
  avatar: {
    width: "40px",
    height: "40px",
    marginRight: theme.spacing(2),
  },
  loader: {
    position: "absolute",
    width: "100%",
    left: 0,
    top: 0,
  },
  button: {
    display: "flex",
    flexDirection: "row",
    minHeight: "60px",
    background:
      theme.palette.type === AppTheme.Light
        ? "rgba(0,0,0,.04)"
        : "rgba(255,255,255,.04)",
    transition: "background .3s ease",
    padding: "10px",
    width: "100%",
    "&:hover": {
      background:
        theme.palette.type === AppTheme.Light
          ? "rgba(0,0,0,.1)"
          : "rgba(255,255,255,.1)",
    },
  },
  userInfo: {
    flex: 1,
    minWidth: "0px",
  },
  progress: {
    height: "30px",
    margin: "auto",
  },
}));

export const DashboardManagerComponent: React.FunctionComponent<Props> = ({
  channel,
  loading,
}: Props) => {
  const classes = useStyles();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);

  const handleClick = React.useCallback(() => setOpen(true), [
    setOpen,
  ]);
  const handleClose = React.useCallback(() => setOpen(false), [
    setOpen,
  ]);
  const handleHover = React.useCallback(
    () => DashboardManagingModal.preload(),
    [],
  );

  React.useEffect(() => setOpen(false), [location.pathname]);

  return (
    <div className={classes.wrapper}>
      {!!loading && (
        <LinearProgress variant="query" className={classes.loader} />
      )}
      <ButtonBase
        className={classes.button}
        onClick={handleClick}
        onMouseOver={handleHover}
      >
        {channel && !loading ? (
          <>
            <Avatar
              src={channel.avatar}
              className={classes.avatar}
              alt={channel.display_name}
            />
            <div className={classes.userInfo}>
              <Typography variant="caption" noWrap>
                Currently Managing
              </Typography>
              <Typography variant="subtitle2" noWrap>
                {channel.display_name}
              </Typography>
            </div>
          </>
        ) : (
          <CircularProgress className={classes.progress} />
        )}
      </ButtonBase>
      {open && <DashboardManagingModal onClose={handleClose} />}
    </div>
  );
};
