export interface Parent {
  id: string;
  parent_id: string;
  parent_type: ParentType;
  created_at: string;
  updated_at: string;
}

export enum ParentType {
  Channel = "channel",
  Preset = "preset",
}
