import {TwitchUser} from "common/models";
import * as React from "react";
import {UserCacheRequester} from "./request";

const requester = new UserCacheRequester();

const activeKeys = new Set<string>();
const seenKeys = new Set<string>();

export interface UserCacheContext {
  getById(id: string): TwitchUser | null | undefined;
  getByIds(ids: string[]): TwitchUser[];
  notFoundId(id: string): boolean;
  notFoundLogin(login: string): boolean;
  getByLogin(login: string): TwitchUser | null | undefined;
  addUsers(users: TwitchUser[]): void;
}

function shrinkSeenKeysCache() {
  if (seenKeys.size < 100) {
    return;
  }
  const values = seenKeys.values();
  while (seenKeys.size > 100) {
    seenKeys.delete(values.next().value);
  }
}

function cleanUpActiveKeys(keys: string[]) {
  for (let i = 0; i < keys.length; ++i) {
    activeKeys.delete(keys[i]);
  }
}

const ctx = React.createContext<UserCacheContext>({
  getById() {
    return null;
  },
  getByIds() {
    return [];
  },
  notFoundId() {
    return false;
  },
  notFoundLogin() {
    return false;
  },
  getByLogin() {
    return null;
  },
  addUsers() {
    //
  },
});

export function useUserCache(): UserCacheContext {
  return React.useContext(ctx);
}

interface ProviderProps {
  children: React.ReactNode;
}

export const UserCacheProvider: React.FunctionComponent<ProviderProps> = (
  props: ProviderProps,
) => {
  const [cache, setCache] = React.useState<
    Record<string, TwitchUser>
  >({});
  const [notFoundKeys, setNotFoundKeys] = React.useState<Set<string>>(
    new Set(),
  );

  const addUsers = React.useCallback(
    (users: TwitchUser[]) => {
      let count = 0;
      const toAdd: Record<string, TwitchUser> = {};
      const keys: string[] = [];
      for (let i = 0; i < users.length; ++i) {
        const user = users[i];
        const idKey = `id.${user.id}`;
        const loginKey = `login.${user.login}`;
        if (!seenKeys.has(idKey) || !seenKeys.has(loginKey)) {
          count++;
          seenKeys.add(idKey);
          seenKeys.add(loginKey);
        }
        keys.push(idKey, loginKey);
        toAdd[idKey] = user;
        toAdd[loginKey] = user;
      }
      requestAnimationFrame(() => shrinkSeenKeysCache());
      requestAnimationFrame(() => cleanUpActiveKeys(keys));
      count && setCache((c) => ({...c, ...toAdd}));
    },
    [setCache],
  );

  const notFound = React.useCallback(
    (keys: string[]) => {
      setNotFoundKeys((s) => {
        const newSet = new Set(s);
        for (let i = 0; i < keys.length; ++i) {
          newSet.add(keys[i]);
        }
        return newSet;
      });
    },
    [setNotFoundKeys],
  );

  React.useEffect(() => {
    requester.onNotFound(notFound);
    return () => requester.onNotFound(undefined);
  }, [notFound]);

  React.useEffect(() => {
    requester.onUsers(addUsers);
    return () => requester.onUsers(undefined);
  }, [addUsers]);

  const getById = React.useCallback(
    (id: string) => {
      if (!id) {
        return null;
      }

      const key = `id.${id}`;
      const cachedUser = cache[key];
      if (cachedUser) {
        return cachedUser;
      }

      if (!activeKeys.has(key)) {
        // handle requesting user
        activeKeys.add(key);
        requester.addId(id);
      }
      return undefined;
    },
    [cache],
  );

  const getByIds = React.useCallback(
    (ids: string[]) => {
      const resp: TwitchUser[] = [];
      for (let i = 0; i < ids.length; ++i) {
        const id = ids[i];
        const key = `id.${id}`;
        const cachedUser = cache[key];
        if (cachedUser) {
          resp.push(cachedUser);
        } else if (!activeKeys.has(key)) {
          activeKeys.add(key);
          requester.addId(id);
        }
      }
      return resp;
    },
    [cache],
  );

  const notFoundId = React.useCallback(
    (id: string) => notFoundKeys.has(`id.${id}`),
    [notFoundKeys],
  );
  const notFoundLogin = React.useCallback(
    (login: string) =>
      notFoundKeys.has(`login.${login.trim().toLowerCase()}`),
    [notFoundKeys],
  );

  const getByLogin = React.useCallback(
    (login: string) => {
      login = (login || "").trim().toLowerCase();
      if (!login) {
        return null;
      }

      const key = `login.${login}`;
      const cachedUser = cache[key];
      if (cachedUser) {
        return cachedUser;
      }

      if (!activeKeys.has(key)) {
        // handle requesting user
        activeKeys.add(key);
        requester.addLogin(login);
      }
      return undefined;
    },
    [cache],
  );

  const value = React.useMemo(
    () => ({
      addUsers,
      getById,
      notFoundId,
      notFoundLogin,
      getByIds,
      getByLogin,
    }),
    [
      addUsers,
      getById,
      notFoundId,
      notFoundLogin,
      getByIds,
      getByLogin,
    ],
  );

  return <ctx.Provider value={value}>{props.children}</ctx.Provider>;
};
