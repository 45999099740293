import {createModel} from "@rematch/core";
import {Bot} from "common/models";
import {commonReducers} from "core/store/helpers";
import {apiRequest} from "core/services/api";
import {ApiClientMethod} from "core/utils/api-client";

export interface BotStore {
  bot: Bot | null;
  loading: number;
  submitting: number;
  error: string;
}

const initialState: BotStore = {
  bot: null,
  loading: 0,
  submitting: 0,
  error: "",
};

export const botStore = createModel({
  state: initialState,

  reducers: {
    ...commonReducers,
    resetState: () => initialState,
    setBot: (state: BotStore, payload: Bot | null) => ({
      ...state,
      bot: payload,
    }),
    setJoined: (state: BotStore, payload: boolean) => ({
      ...state,
      bot: {
        ...state.bot,
        joined: payload,
      },
    }),
    setMuted: (state: BotStore, payload: boolean) => ({
      ...state,
      bot: {
        ...state.bot,
        muted: payload,
      },
    }),
  },

  effects: () => ({
    getBot(parent: string) {
      this.setError("");
      apiRequest({
        method: ApiClientMethod.GET,
        url: `v2/bots/${encodeURIComponent(parent)}`,
        onLoadStart: this.incrementLoading,
        onLoadEnd: this.decrementLoading,
        onSuccess: this.setBot,
        onError: this.setError,
      });
    },

    joinBot({parent, done}: {parent: string; done: () => void}) {
      this.setError("");
      apiRequest({
        method: ApiClientMethod.PATCH,
        url: `v2/bots/${encodeURIComponent(parent)}/join`,
        onLoadStart: this.incrementSubmitting,
        onLoadEnd: this.decrementSubmitting,
        body: "",
        onSuccess: () => {
          this.setJoined(true);
          done();
        },
        onError: this.setError,
      });
    },

    partBot({parent, done}: {parent: string; done: () => void}) {
      this.setError("");
      apiRequest({
        method: ApiClientMethod.PATCH,
        url: `v2/bots/${encodeURIComponent(parent)}/part`,
        body: "",
        onLoadStart: this.incrementSubmitting,
        onLoadEnd: this.decrementSubmitting,
        onSuccess: () => {
          this.setJoined(false);
          done();
        },
        onError: this.setError,
      });
    },

    muteBot(parent: string) {
      this.setError("");
      apiRequest({
        method: ApiClientMethod.PATCH,
        url: `v2/bots/${encodeURIComponent(parent)}/mute`,
        onLoadStart: this.incrementSubmitting,
        onLoadEnd: this.decrementSubmitting,
        onSuccess: () => this.setMuted(true),
        onError: this.setError,
      });
    },

    unmuteBot(parent: string) {
      this.setError("");
      apiRequest({
        method: ApiClientMethod.PATCH,
        url: `v2/bots/${encodeURIComponent(parent)}/unmute`,
        onLoadStart: this.incrementSubmitting,
        onLoadEnd: this.decrementSubmitting,
        onSuccess: () => this.setMuted(false),
        onError: this.setError,
      });
    },
  }),
});
