import {TwitchUser} from "common/models";
import {twitchApiRequest} from "core/services/twitch-api";
import {ApiClientMethod} from "core/utils/api-client";

export class UserCacheRequester {
  private buffer: {ids: Set<string>; logins: Set<string>} = {
    ids: new Set(),
    logins: new Set(),
  };
  private buffering = false;
  private timeout?: NodeJS.Timeout;
  private notFoundCallback?: (keys: string[]) => void;
  private usersCallback?: (users: TwitchUser[]) => void;

  public onUsers(cb?: (users: TwitchUser[]) => void) {
    this.usersCallback = cb;
  }

  public onNotFound(cb?: (keys: string[]) => void) {
    this.notFoundCallback = cb;
  }

  public close() {
    this.timeout && clearTimeout(this.timeout);
  }

  public addId(userId: string) {
    this.buffer.ids.add(userId);
    this.startBuffer();
  }

  public addLogin(userLogin: string) {
    this.buffer.logins.add(userLogin);
    this.startBuffer();
  }

  private flush() {
    const idsSet = this.buffer.ids;
    const loginsSet = this.buffer.logins;
    const ids = Array.from(this.buffer.ids);
    const logins = Array.from(this.buffer.logins);
    this.resetBuffer();
    requestAnimationFrame(() => {
      twitchApiRequest({
        method: ApiClientMethod.GET,
        url: "https://api.twitch.tv/helix/users",
        query: {id: ids, login: logins},
        onSuccess: (body: {data: TwitchUser[]}) => {
          const users = body?.data || [];
          users.length && this.usersCallback?.(users);

          for (let i = 0; i < users.length; ++i) {
            const user = users[i];
            idsSet.delete(user.id);
            loginsSet.delete(user.login);
          }
          const resp = [
            ...Array.from(idsSet),
            ...Array.from(loginsSet),
          ];
          resp.length && this.notFoundCallback?.(resp);
        },
      });
    });
  }

  private startBuffer() {
    if (this.buffering) {
      if (this.buffer.ids.size + this.buffer.logins.size >= 99) {
        // buffer is already at twitch api limit, flush early.
        this.flush();
      }
    } else {
      this.buffering = true;
      this.timeout = setTimeout(() => this.flush(), 1);
    }
  }

  private resetBuffer() {
    this.buffer = {ids: new Set(), logins: new Set()};
    this.buffering = false;
    this.timeout && clearTimeout(this.timeout);
  }
}
