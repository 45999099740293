import * as React from "react";
import {User} from "common/models/user";
import {
  Avatar,
  IconButton,
  makeStyles,
  Theme,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
} from "@material-ui/core";
import {ExitToApp} from "@material-ui/icons";

export interface ReduxStateProps {
  user: User | null;
}

export interface ReduxDispatchProps {
  logout(): void;
}

export interface PublicProps {}

type Props = ReduxStateProps & ReduxDispatchProps & PublicProps;

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    padding: theme.spacing(0.5),
  },
  container: {
    marginLeft: theme.spacing(2),
  },
  placeholder: {
    backgroundColor: theme.palette.action.hover,
    width: "40px",
    height: "40px",
    margin: theme.spacing(0.5),
    borderRadius: "20px",
  },
  listItem: {
    paddingRight: theme.spacing(2),
  },
  listIcon: {
    minWidth: "40px",
  },
}));

export const TopNavUserBoxComponent: React.FunctionComponent<Props> = ({
  user,
  logout,
}: Props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(
    null,
  );

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <div className={classes.container}>
        {user ? (
          <IconButton className={classes.button} onClick={handleOpen}>
            <Avatar alt={user.display_name} src={user.avatar} />
          </IconButton>
        ) : (
          <div className={classes.placeholder} />
        )}
      </div>
      <Menu
        id="top-nav-userbox-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
      >
        <MenuItem
          className={classes.listItem}
          dense
          onClick={() => logout()}
        >
          <ListItemIcon className={classes.listIcon}>
            <ExitToApp />
          </ListItemIcon>
          <Typography variant="inherit">Sign out</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};
