import {RoleScope} from "common/models";
import * as React from "react";
import {useScopeValidate} from "web/dashboard/hooks/use-scope-validate";

interface Props {
  children: React.ReactNode;
  scope: RoleScope | RoleScope[];
}

function DashboardScopeGuardComponent(props: Props) {
  const hasAccess = useScopeValidate(props.scope);
  return hasAccess ? props.children : null;
}

export const DashboardScopeGuard = React.memo(
  DashboardScopeGuardComponent as React.FunctionComponent<Props>,
);
