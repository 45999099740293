import * as React from "react";
import * as ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {Config} from "../../common/config";
import {AppStorage} from "../utils/storage";
import {Store, store} from "core/store/store";
import {userSession, UserSession} from "core/utils/user-session";
import {SnackbarUtil} from "core/utils/snackbar";

export class App {
  public config: Config;
  public store: Store;
  public session: UserSession;
  public snackbarUtil: SnackbarUtil;
  public storage: AppStorage;

  public constructor(config: Config) {
    this.config = config;
  }

  public init() {
    this.session = userSession;
    this.storage = new AppStorage(localStorage);
    this.snackbarUtil = new SnackbarUtil();
    this.store = store;
  }

  public mount(element: JSX.Element, root: HTMLElement) {
    ReactDOM.render(this.wrap(element), root);
  }

  public wrap(children: JSX.Element): JSX.Element {
    return <Provider store={this.store}>{children}</Provider>;
  }
}

export const config = new Config();
export const app: App = new App(config);
