export enum ModuleType {
  Bingo = "bingo",
  Duel = "duel",
  EightBall = "eightball",
  EmoteCombo = "emote_combo",
  EmotePyramid = "emote_pyramid",
  Raffle = "raffle",
  Roulette = "roulette",
  SlotMachine = "slot_machine",
  SongRequest = "song_request",
}

export type AnyModule =
  | BingoModule
  | DuelModule
  | EightballModule
  | EmoteComboModule
  | EmotePyramidModule
  | RaffleModule
  | RouletteModule
  | SlotMachineModule
  | SongRequestModule;

export interface Modules {
  [ModuleType.Bingo]: BingoModule;
  [ModuleType.Duel]: DuelModule;
  [ModuleType.EightBall]: EightballModule;
  [ModuleType.EmoteCombo]: EmoteComboModule;
  [ModuleType.EmotePyramid]: EmotePyramidModule;
  [ModuleType.Raffle]: RaffleModule;
  [ModuleType.Roulette]: RouletteModule;
  [ModuleType.SlotMachine]: SlotMachineModule;
  [ModuleType.SongRequest]: SongRequestModule;
}

export interface ModulesEnabledStatus {
  [ModuleType.Bingo]: boolean;
  [ModuleType.Duel]: boolean;
  [ModuleType.EightBall]: boolean;
  [ModuleType.EmoteCombo]: boolean;
  [ModuleType.EmotePyramid]: boolean;
  [ModuleType.Raffle]: boolean;
  [ModuleType.Roulette]: boolean;
  [ModuleType.SlotMachine]: boolean;
  [ModuleType.SongRequest]: boolean;
}

export interface BingoModule {
  enabled: boolean;
  enabled_provider_emotes: boolean;
  enabled_betterttv_emotes: boolean;
  enabled_frankerfacez_emotes: boolean;
  max_wager: number;
}

export interface DuelModuleMessages {
  win: string;
  win_wager: string;
}

export interface DuelModule {
  enabled: boolean;
  max_wager: number;
  messages: DuelModuleMessages;
}

export interface EightballModule {
  enabled: boolean;
  emotes: string[];
  messages: string[];
}

export interface EmoteComboModuleMessage {
  count: number;
  message: string;
}

export interface EmoteComboModule {
  enabled: boolean;
  cooldown: number;
  threshold: number;
  messages: EmoteComboModuleMessage[];
}

export interface EmotePyramidModuleMessage {
  width: number;
  message: string;
}

export interface EmotePyramidModule {
  enabled: boolean;
  threshold: number;
  cooldown: number;
  messages: EmotePyramidModuleMessage[];
}

export interface RaffleModuleModeMessages {
  start: string;
  running: string;
  end: string;
}

export interface RaffleModuleMode {
  duration: number;
  max_pot: number;
  messages: RaffleModuleModeMessages;
}

export interface RaffleModule {
  enabled: boolean;
  enabled_newsub: boolean;
  enabled_resub: boolean;
  single: RaffleModuleMode;
  multi: RaffleModuleMode;
}

export interface RouletteModuleMessages {
  all_win: string;
  all_lose: string;
  win: string;
  lose: string;
}

export interface RouletteModule {
  enabled: boolean;
  luck: number;
  min_wager: number;
  messages: RouletteModuleMessages;
}

export interface SlotMachineModuleMessages {
  win: string;
  lose: string;
}

export interface SlotMachineModule {
  enabled: boolean;
  emotes: string[];
  min_wager: number;
  luck: number;
  messages: SlotMachineModuleMessages;
}

export interface SongRequestModuleVoteskip {
  enabled: boolean;
  required_votes: number;
}

export interface SongRequestModule {
  enabled: boolean;
  cost: number;
  subscriber_discount: number;
  voteskip: SongRequestModuleVoteskip;
}
