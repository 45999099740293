export function chunkArray(
  input: unknown[],
  chunkSize: number,
): unknown[][] {
  const results: unknown[][] = [];

  while (input.length) {
    results.push(input.splice(0, chunkSize));
  }

  return results;
}
