export class AppStorage {
  private cache = new Map<string, unknown>();
  private storage: Storage;

  public constructor(storage: Storage) {
    this.storage = storage;
  }

  public get<T>(key: string, defaultValue: T): T {
    if (this.cache.has(key)) {
      return this.cache.get(key) as T;
    }

    let serializedValue: string | null = null;
    try {
      serializedValue = this.storage.getItem(key);
    } catch (error) {
      console.warn("Failed to load value from store", key, error);
    }

    if (serializedValue === null || serializedValue === "undefined") {
      return defaultValue;
    }

    try {
      return JSON.parse(serializedValue) as T;
    } catch (error) {
      console.warn(
        "Failed to deserialize stored value.",
        {key, value: serializedValue},
        error,
      );
      this.remove(key);
      return defaultValue;
    }
  }

  public getOptional<T>(key: string): T | null {
    return this.get(key, null);
  }

  public set(key: string, value: {}) {
    let serializedValue: string;

    try {
      serializedValue = JSON.stringify(value);
    } catch (error) {
      console.warn(
        "Failed to serialize value to store",
        {key, value},
        error,
      );
      throw error;
    }

    this.cache.set(key, value);

    try {
      this.storage.setItem(key, serializedValue);
    } catch (error) {
      console.warn(
        "Failed to save value to storage",
        {key, value, serializedValue},
        error,
      );
    }
  }

  public remove(key: string) {
    this.cache.delete(key);
    try {
      this.storage.removeItem(key);
    } catch (error) {
      console.warn(`Failed to remove ${key} from storage`, error);
    }
  }

  public clear() {
    this.cache = new Map();
    try {
      this.storage.clear();
    } catch (error) {
      console.warn("Failed to clear storage", error);
    }
  }
}
