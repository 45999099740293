import {
  ListItem,
  ListItemText,
  makeStyles,
  SvgIconProps,
  Theme,
} from "@material-ui/core";
import {RoleScope} from "common/models";
import * as React from "react";
import {NavLink} from "react-router-dom";
import {DashboardScopeGuard} from "web/dashboard/components/dashboard-scope-guard";

interface Props {
  external?: boolean;
  icon: React.ComponentType<SvgIconProps>;
  label: string;
  url: string;
  scopes: RoleScope[];
}

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  link: {
    color: theme.palette.text.secondary,
    textDecoration: "none",
  },
  linkActive: {
    color: theme.palette.primary.main,
  },
  listItemIcon: {
    fontSize: 17,
    display: "flex",
    alignItems: "center",
    minWidth: "24px",
    textAlign: "center",
  },
  listItemText: {
    fontSize: 13.5,
    marginLeft: "17px",
    fontWeight: 500,
  },
}));

const DashboardSidebarItemComponent: React.FunctionComponent<Props> = (
  props: Props,
) => {
  const classes = useStyles();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Component: any = props.external ? "a" : NavLink;
  const Icon = props.icon;

  return (
    <DashboardScopeGuard scope={props.scopes}>
      <Component
        exact={props.url.endsWith("/dashboard")}
        to={props.external ? undefined : props.url}
        href={props.external ? props.url : undefined}
        target={props.external ? "_blank" : undefined}
        rel={props.external ? "noopener noreferrer" : undefined}
        className={classes.link}
        {...(props.external
          ? {}
          : {activeClassName: classes.linkActive})}
      >
        <ListItem button disableGutters className={classes.listItem}>
          <span className={classes.listItemIcon}>
            <Icon height={17} width={17} />
          </span>
          <ListItemText
            primary={props.label}
            classes={{primary: classes.listItemText}}
          />
        </ListItem>
      </Component>
    </DashboardScopeGuard>
  );
};

export const DashboardSidebarItem = React.memo(
  DashboardSidebarItemComponent,
);
