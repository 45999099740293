import * as React from "react";
import ReactLoadable from "react-loadable";

export function Loadable<T extends object = {}>(
  loader: () => Promise<Record<string, unknown>>,
  namedExport: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loading: any = () => null,
): ReactLoadable.LoadableComponent & React.FunctionComponent<T> {
  return ReactLoadable<React.FunctionComponent, T>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    loader: loader,
    loading: loading,
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    render(
      loaded: Record<
        string,
        React.ComponentClass<T> | React.FunctionComponent<T>
      >,
      props: T,
    ) {
      const Component = loaded[namedExport];
      return <Component {...props} />;
    },
  });
}
