import * as React from "react";
import {ListSubheader, makeStyles, Theme} from "@material-ui/core";

interface Props {
  children: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
}));

export const DashboardSidebarHeader: React.FunctionComponent<Props> = (
  props: Props,
) => {
  const classes = useStyles();

  return (
    <ListSubheader
      className={classes.header}
      disableSticky
      disableGutters
      {...props}
    >
      {props.children}
    </ListSubheader>
  );
};
