import {createModel} from "@rematch/core";
import {Role} from "common/models";
import {commonReducers} from "core/store/helpers";
import {apiRequest} from "core/services/api";
import {ApiClientMethod} from "core/utils/api-client";

export interface RolesStore {
  error: string;
  loading: number;
  roles: Role[];
}

const initialState: RolesStore = {
  error: "",
  loading: 0,
  roles: [],
};

export const rolesStore = createModel({
  state: initialState,

  reducers: {
    ...commonReducers,
    setRoles: (state: RolesStore, payload: Role[]) => ({
      ...state,
      roles: payload,
    }),
  },

  effects: () => ({
    getRoles(parent: string) {
      this.setError("");
      apiRequest({
        method: ApiClientMethod.GET,
        url: `v2/roles/${encodeURIComponent(parent)}`,
        onLoadStart: this.incrementLoading,
        onLoadEnd: this.decrementLoading,
        onSuccess: (body: {data: Role[]}) => this.setRoles(body.data),
        onError: this.setError,
      });
    },
  }),
});
