import {ApiClient, ApiClientResponse} from "core/utils/api-client";
import {ApiServiceOptions} from "core/utils/api-service-base";
import {RequestError} from "core/utils/request-error";
import {app} from "core/app";

declare const process: {
  env: {
    REACT_APP_TWITCH_CLIENT_ID: string;
  };
};

export const twitchApiClient = new ApiClient({
  headers: {
    Accept: "application/json",
    "Client-ID": process.env.REACT_APP_TWITCH_CLIENT_ID,
  },
});

export interface TwitchHelixResponse<T> {
  data: T[];
}

export function setTwitchApiToken(token: string, clientId: string) {
  twitchApiClient.headers["Authorization"] = `Bearer ${token}`;
  twitchApiClient.headers["Client-ID"] = clientId;
}

export function twitchApiRequest<T>(
  options: ApiServiceOptions<T>,
): unknown {
  options.onLoadStart && options.onLoadStart();
  return twitchApiClient
    .request<T>(options)
    .then((response: ApiClientResponse<T>) => {
      options.onLoadEnd && options.onLoadEnd();
      if (response.status !== 200) {
        if (
          response.status === 401 &&
          app.store.getState().userStore.user
        ) {
          // handle refreshing twitch token.
          app.store.dispatch.userStore.getUserTokens(() =>
            twitchApiRequest(options),
          );
        } else {
          throw new Error(
            `Non 200 http status code returned: ${response.status}`,
          );
        }
      } else {
        return (
          options.onSuccess && options.onSuccess(response.data as T)
        );
      }
    })
    .catch((error) => {
      options.onLoadEnd && options.onLoadEnd();
      console.log(error);
      options.onError &&
        options.onError(new RequestError(error.toString(), 500));
    });
}
