import {ChatRole} from "common/models";
import {apiRequest} from "core/services/api";
import {ApiClientMethod} from "core/utils/api-client";
import * as React from "react";
import {useParent} from "web/common/hooks/use-parent";

export interface RolesContextOption {
  label: string;
  value: string;
}

export interface RolesContextState {
  error: string;
  loading: boolean;
  roles: ChatRole[];
  map: Record<string, ChatRole>;
  loadRoles(): void;
  transformToOptions(roleIds: string[]): RolesContextOption[];
}

const ctx = React.createContext<RolesContextState>({
  error: "",
  loading: false,
  roles: [],
  map: {},
  loadRoles: () => null,
  transformToOptions: () => [],
});

interface Props {
  children: React.ReactNode;
}

interface UseRolesOptions {
  load?: boolean;
}

export function useRoles(opts?: UseRolesOptions): RolesContextState {
  const ctx_ = React.useContext(ctx);
  const loadRoles_ = opts?.load ?? false;

  const {loadRoles} = ctx_;

  React.useEffect(() => {
    loadRoles && loadRoles();
  }, [loadRoles_, loadRoles]);

  return ctx_;
}

export const RolesProvider: React.FunctionComponent<Props> = (
  props: Props,
) => {
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [roles, setRoles] = React.useState<ChatRole[]>([]);
  const [map, setMap] = React.useState<Record<string, ChatRole>>({});
  const parent = useParent();

  const loadRoles = React.useCallback(() => {
    setError("");
    setLoading(false);
    apiRequest({
      method: ApiClientMethod.GET,
      url: `v2/roles/${encodeURIComponent(parent.id)}/chat`,
      onLoadStart: () => setLoading(true),
      onLoadEnd: () => setLoading(false),
      onError: (error) => setError(error.toString()),
      onSuccess: (body: {data: ChatRole[]}) => {
        setRoles(body?.data ?? []);
        setMap(
          (body?.data ?? []).reduce((acc, cur) => {
            acc[cur.id] = cur;
            return acc;
          }, {} as Record<string, ChatRole>),
        );
      },
    });
  }, [setError, setLoading, setRoles, setMap, parent]);

  const transformToOptions = React.useCallback(
    (roleIds: string[]) => {
      const resp: RolesContextOption[] = [];
      for (let i = 0; i < roleIds.length; ++i) {
        const role = map[roleIds[i]];
        role && resp.push({label: role.name, value: role.id});
      }
      return resp;
    },
    [map],
  );

  const value = React.useMemo(
    () => ({
      error,
      loading,
      roles,
      loadRoles,
      map,
      transformToOptions,
    }),
    [error, loading, roles, loadRoles, map, transformToOptions],
  );

  return <ctx.Provider value={value}>{props.children}</ctx.Provider>;
};
