import * as React from "react";
import {makeStyles, Theme, IconButton} from "@material-ui/core";
import {Menu} from "@material-ui/icons";

interface Props {
  open: boolean;
  setOpen(open: boolean): void;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: theme.palette.common.white,
  },
}));

export const TopBarSidebarToggle: React.FunctionComponent<Props> = (
  props: Props,
) => {
  const classes = useStyles();
  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    props.setOpen(!props.open);
  };

  return (
    <IconButton onClick={handleClick} className={classes.button}>
      <Menu />
    </IconButton>
  );
};
