import {connect} from "react-redux";
import {
  ReduxStateProps,
  ReduxDispatchProps,
  TopBarThemeToggleComponent,
} from "./component";
import {RootState, Dispatch} from "core/store/store";

function mapStateToProps(state: RootState): ReduxStateProps {
  return {theme: state.userSettingsStore!.theme};
}

function mapDispatchToProps(dispatch: Dispatch): ReduxDispatchProps {
  return {
    changeTheme: dispatch.userSettingsStore.setTheme,
  };
}

export const TopBarThemeToggle: React.FunctionComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopBarThemeToggleComponent);
