export enum ResponseType {
  Say = "say",
  Reply = "reply",
  ReplyThread = "reply_thread",
  Whisper = "whisper",
}

export const ResponseTypeStrings: Record<ResponseType, string> = {
  [ResponseType.Say]: "Say",
  [ResponseType.Reply]: "Reply",
  [ResponseType.ReplyThread]: "Reply (in thread)",
  [ResponseType.Whisper]: "Whisper",
};
