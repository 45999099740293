import * as React from "react";

interface Props extends React.HTMLAttributes<SVGElement> {
  fill?: string;
  height?: string;
  style?: React.StyleHTMLAttributes<SVGElement>;
}

export const TwitterBird: React.FunctionComponent<Props> = (
  props: Props,
) => {
  const {
    fill = "#1da1f2",
    height = "100px",
    style = {},
    ...extraProps
  } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      height={height}
      style={{fill, ...style}}
      {...extraProps}
    >
      <path d="M19 3.844a7.07 7.07 0 01-2.12.62 3.912 3.912 0 001.623-2.178 7.075 7.075 0 01-2.345.955 3.613 3.613 0 00-2.694-1.24c-2.04 0-3.694 1.76-3.694 3.94 0 .31.037.61.1.9C6.8 6.668 4.078 5.1 2.253 2.72c-.33.606-.5 1.286-.498 1.977 0 1.363.653 2.567 1.643 3.273a3.49 3.49 0 01-1.672-.493v.05c0 1.906 1.272 3.496 2.963 3.857-.55.156-1.12.18-1.67.067.47 1.57 1.83 2.7 3.45 2.73a7.07 7.07 0 01-5.47 1.63 9.954 9.954 0 005.66 1.77c6.79 0 10.51-5.99 10.51-11.19 0-.17-.01-.34-.01-.51.73-.56 1.35-1.25 1.84-2.03" />
    </svg>
  );
};
