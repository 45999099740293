import {connect} from "react-redux";
import {RootState} from "core/store/store";
import {
  ReduxStateProps,
  PublicProps,
  DashboardManagerComponent,
} from "./component";

function mapStateToProps(state: RootState): ReduxStateProps {
  return {
    channel: state.channelStore.channel,
    loading: state.channelStore.loading,
  };
}

export const DashboardManager: React.FunctionComponent<PublicProps> = connect(
  mapStateToProps,
)(DashboardManagerComponent);
