import * as React from "react";
import {AppTheme} from "common/models";
import {saveTheme} from "core/utils/theme";

export interface ReduxStateProps {
  theme: AppTheme;
}

export interface PublicProps {}

type Props = PublicProps & ReduxStateProps;

export const ThemeManagerComponent: React.FunctionComponent<Props> = (
  props: Props,
) => {
  React.useEffect(() => {
    saveTheme(props.theme);
  }, [props.theme]);

  return null;
};
