import * as React from "react";
import {makeStyles, Theme} from "@material-ui/core";
import {Link} from "react-router-dom";

interface Props {
  label: string;
  linkTo: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: 0,
    padding: 0,
  },
  link: {
    color: theme.palette.grey[400],
    // fontWeight: 600,
    display: "block",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    "&:hover": {
      color: theme.palette.common.white,
    },
  },
}));

export const WebFooterNavItem: React.FunctionComponent<Props> = (
  props: Props,
) => {
  const classes = useStyles();
  const externalLink = !props.linkTo.startsWith("/");

  return (
    <li className={classes.container}>
      {externalLink ? (
        <a
          href={props.linkTo}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          {props.label}
        </a>
      ) : (
        <Link to={props.linkTo} className={classes.link}>
          {props.label}
        </Link>
      )}
    </li>
  );
};
