import {connect} from "react-redux";
import {RootState, Dispatch} from "core/store/store";
import {
  ReduxStateProps,
  PublicProps,
  TopNavUserBoxComponent,
  ReduxDispatchProps,
} from "./component";

function mapStateToProps(state: RootState): ReduxStateProps {
  return {user: state.userStore.user};
}

function mapDispatchToProps(dispatch: Dispatch): ReduxDispatchProps {
  return {
    logout: dispatch.userStore.logout,
  };
}

export const TopNavUserBox: React.FunctionComponent<PublicProps> = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopNavUserBoxComponent);
