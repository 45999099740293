import {connect} from "react-redux";
import {RootState, Dispatch} from "core/store/store";
import {
  ReduxStateProps,
  ReduxDispatchProps,
  PublicProps,
  HomeHeroComponent,
} from "./component";

function mapStateToProps(state: RootState): ReduxStateProps {
  return {
    authenticated: state.userStore.authorized,
    channel: state.userStore.userChannel,
  };
}

function mapDispatchToProps(dispatch: Dispatch): ReduxDispatchProps {
  return {
    getUserChannelIfNeeded: dispatch.userStore.getUserChannelIfNeeded,
  };
}

export const HomeHero: React.FunctionComponent<PublicProps> = connect(
  mapStateToProps,
  mapDispatchToProps,
)(HomeHeroComponent);
