import {chunkArray} from "helpers";
import {ApiClientMethod} from "./api-client";
import {twitchApiRequest} from "core/services/twitch-api";
import {TwitchHelixResponse, TwitchUser} from "common/models/twitch";

const numRegex = /^[0-9]+$/;

export class TwitchUserCache {
  private loginCache = new Map<string, TwitchUser>();
  private idCache = new Map<string, TwitchUser>();

  public getLogins(
    logins: string[],
  ): Promise<Map<string, TwitchUser>> {
    const users = new Map<string, TwitchUser>();
    const querySet = new Set<string>();

    // Loop through and remove any cached user objects we already have.
    for (let i = logins.length - 1; i >= 0; i--) {
      const login = logins[i].trim().toLowerCase();
      const user = this.loginCache.get(login);
      if (user) {
        users.set(logins[i], user);
        logins.splice(i, 1);
      } else {
        querySet.add(login);
      }
    }

    if (!logins.length) {
      return Promise.resolve(users);
    }

    return this.request("login", logins);
  }

  public getIDs(ids: string[]): Promise<Map<string, TwitchUser>> {
    const users = new Map<string, TwitchUser>();
    const querySet = new Set<string>();

    // Loop through and remove any cached user objects we already have.
    for (let i = ids.length - 1; i >= 0; i--) {
      const id = ids[i].trim().toLowerCase();
      if (!numRegex.test(id)) {
        continue;
      }
      const user = this.idCache.get(id);
      if (user) {
        users.set(ids[i], user);
        ids.splice(i, 1);
      } else {
        querySet.add(id);
      }
    }

    if (!ids.length) {
      return Promise.resolve(users);
    }

    return this.request("id", ids);
  }

  private request(
    key: string,
    values: string[],
  ): Promise<Map<string, TwitchUser>> {
    // Max number of queries in a single request is 100.
    const requests = chunkArray(Array.from(new Set(values)), 100);
    const users = new Map<string, TwitchUser>();

    return Promise.all(
      requests.map(
        (items: string[]) =>
          new Promise((resolve, reject) => {
            twitchApiRequest({
              method: ApiClientMethod.GET,
              url: "https://api.twitch.tv/helix/users",
              query: {
                [key]: items,
              },
              onSuccess: (res: TwitchHelixResponse<TwitchUser>) =>
                resolve(res.data),
              onError: (error) => {
                console.error(error);
                reject(error);
              },
            });
          }),
      ),
    ).then((requests: TwitchUser[][]) => {
      for (const request of requests) {
        for (const user of request) {
          this.loginCache.set(user.login, user);
          this.idCache.set(user.id, user);
          users.set(key === "id" ? user.id : user.login, user);
        }
      }

      return users;
    });
  }
}

export const twitchUserCache = new TwitchUserCache();
