import {ExpireType} from "./expire-type";

export interface Banphrase {
  id: string;
  phrase: string;
  reason: string;
  target: BanphraseTarget;
  enabled: boolean;
  enabled_offline: boolean;
  enabled_online: boolean;
  regex: boolean;
  case_sensitive: boolean;
  announcement: BanphraseAnnouncement;
  expiry: BanphraseExpiry;
  repeat_offender: BanphraseRepeatOffender;
  sanction: BanphraseSanction;
  warning: BanphraseWarning;
  allowed_role_ids: string[];
  blocked_role_ids: string[];
  games: BanphraseGame[];
}

export interface BanphraseGame {
  provider_id: string;
}

export interface BanphraseAnnouncement {
  enabled: boolean;
  cooldown: number;
}

export interface BanphraseExpiry {
  type: ExpireType;
  expires_at: string | null;
}

export interface BanphraseRepeatOffender {
  enabled: boolean;
  cooldown: number;
  multiplier: number;
}

export interface BanphraseSanction {
  base: number;
  max: number;
}

export interface BanphraseWarning {
  enabled: boolean;
  duration: number;
}

export enum BanphraseTarget {
  StartsWith = "starts_with",
  Contains = "contains",
  EndsWith = "ends_with",
}
