import * as React from "react";
import {connect} from "react-redux";
import {RootState} from "core/store/store";
import {
  ReduxStateProps,
  PublicProps,
  MuiBaseComponent,
} from "./component";

function mapStateToProps(state: RootState): ReduxStateProps {
  return {
    theme: state.userSettingsStore!.theme,
  };
}

export const MuiBase: React.FunctionComponent<PublicProps> = connect(
  mapStateToProps,
)(MuiBaseComponent);
