import * as React from "react";
import {makeStyles, Theme} from "@material-ui/core";
import {LogoTextmark} from "../logo-textmark";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: "relative",
  },
  beta: {
    textTransform: "uppercase",
    fontWeight: 700,
    color: theme.palette.common.white,
    fontSize: "10px",
    position: "absolute",
    top: -8,
    right: -30,
  },
}));

export const BrandLogo: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <LogoTextmark height={32} />
      <span className={classes.beta}>Beta</span>
    </div>
  );
};
