import * as React from "react";
import {Switch, Route} from "react-router-dom";
import {Dashboard} from "web/dashboard";
import {Main} from "web/main";

export const Router: React.FunctionComponent = () => {
  return (
    <Switch>
      {/* We define each here, so we don't need to validate /c/ and /p/ further down, instead, it won't match here and provides out of the box validaton.*/}
      <Route path="/c/:parent" component={Dashboard} />
      <Route path="/" component={Main} />
    </Switch>
  );
};
