import {init, RematchRootState, RematchDispatch} from "@rematch/core";
import {GlobalStore} from "./model";
import * as stores from "../stores";

export const store = init({
  models: stores,
});

export type Store = typeof store;
export type Dispatch = RematchDispatch<GlobalStore>;
export type RootState = RematchRootState<GlobalStore>;
