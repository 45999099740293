import * as React from "react";
import {makeStyles} from "@material-ui/core";
import {CenterProgress} from "web/common/components/center-progress";

const useStyles = makeStyles({
  container: {
    flex: 1,
    alignItems: "center",
    display: "flex",
    height: "100%",
    margin: "auto",
  },
});

export const DashboardLoader: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <CenterProgress />
    </div>
  );
};
