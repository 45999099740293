import * as React from "react";

interface Props extends React.HTMLAttributes<SVGElement> {
  fill?: string;
  height?: string;
  style?: React.StyleHTMLAttributes<SVGElement>;
}

export const TwitchGlitch: React.FunctionComponent<Props> = (
  props: Props,
) => {
  const {
    fill = "#9046FF",
    height = "100px",
    style = {},
    ...extraProps
  } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 3000 3000"
      version="1.1"
      height={height}
      style={{fill, ...style}}
      {...extraProps}
    >
      <path
        d="M500,0L0,500v1800h600v500l500-500h400l900-900V0H500z M2200,1300l-400,400h-400l-350,350v-350H600V200h1600
				V1300z"
      />
      <rect x="1700" y="550" width="200" height="600" />
      <rect x="1150" y="550" width="200" height="600" />
    </svg>
  );
};
