import * as React from "react";
import {makeStyles} from "@material-ui/core";
import {Channel} from "common/models";
import {HomeHeroImage} from "../home-hero-image";
import {HomeHeroCTA} from "../home-hero-cta";
import {MainHeroText} from "web/main/components/main-hero-text";
import {MainHeroWrapper} from "web/main/components/main-hero-wrapper";

export interface ReduxStateProps {
  authenticated: boolean | null;
  channel: Channel | null;
}

export interface ReduxDispatchProps {
  getUserChannelIfNeeded(): void;
}

export interface PublicProps {}

const useStyles = makeStyles(() => ({
  ctaContainer: {
    minHeight: "36px",
  },
}));

type Props = ReduxStateProps & ReduxDispatchProps & PublicProps;

export const HomeHeroComponent: React.FunctionComponent<Props> = (
  props: Props,
) => {
  const classes = useStyles();
  const {authenticated, getUserChannelIfNeeded} = props;

  React.useEffect(() => {
    authenticated && getUserChannelIfNeeded();
  }, [authenticated, getUserChannelIfNeeded]);

  return (
    <MainHeroWrapper>
      <MainHeroText
        isHome
        title="The ultimate high performance, feature packed chat bot for Twitch."
        subtitle="Fossabot is a Twitch chat bot that has all the features you need
            to create the ultimate chat experience for yourself and your audience.
            Built by the community, for the community."
      />
      <div className={classes.ctaContainer}>
        {props.authenticated !== null && (
          <HomeHeroCTA
            authenticated={props.authenticated}
            channel={props.channel}
          />
        )}
      </div>
      <HomeHeroImage />
    </MainHeroWrapper>
  );
};
