import * as React from "react";
import {makeStyles, Theme} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: "750px",
    width: "100%",
    margin: "0 auto",
    paddingTop: theme.spacing(12),
  },
  image: {
    width: "100%",
    height: "auto",
    position: "relative",
    bottom: "-5px",
  },
}));

export const HomeHeroImage: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <img
        src="https://fossabot.com/img/fossabot-screenshot.png"
        alt="Fossabot"
        className={classes.image}
      />
    </div>
  );
};
