import {store} from "core/store/store";
import {ApiClient} from "core/utils/api-client";
import {ApiServiceOptions} from "core/utils/api-service-base";
import {RequestError} from "core/utils/request-error";
import {userSession} from "core/utils/user-session";

declare const process: {
  env: {
    REACT_APP_API_BASE: string;
  };
};

const headers: Record<string, string> = {
  "x-device": "web",
  "content-type": "application/json",
};

if (userSession.hasToken()) {
  headers["Authorization"] = `Bearer ${userSession.getToken()}`;
}

export const apiClient = new ApiClient({
  baseUrl: process.env.REACT_APP_API_BASE,
  headers: headers,
});

export function apiRequest<T>(options: ApiServiceOptions<T>) {
  if (options.onLoadStart) {
    options.onLoadStart();
  }

  apiClient
    .request(options)
    .then((response) => {
      if (options.onLoadEnd) {
        options.onLoadEnd();
      }
      if (response.status === 401) {
        options.onError &&
          options.onError(
            new RequestError("Please login to continue.", 401),
          );
        return store.dispatch.userStore.logout();
      }

      if (response.status < 300 && response.status >= 200) {
        return options.onSuccess
          ? options.onSuccess(response.data as T)
          : undefined;
      }

      if (
        (response.data as {error: string})?.error &&
        options.onError
      ) {
        return options.onError(
          new RequestError(
            (response.data as {error: string}).error,
            response.status,
          ),
        );
      }

      if (options.onError) {
        options.onError(
          new RequestError(
            `Non 2xx HTTP status code returned: ${response.status}`,
            response.status,
          ),
        );
      }
    })
    .catch((error) => {
      if (options.onLoadEnd) {
        options.onLoadEnd();
      }
      console.log(error);
      if (options.onError) {
        options.onError(new RequestError(error.toString(), 500));
      }
    });
}
