import * as React from "react";
import {makeStyles, Theme, Typography} from "@material-ui/core";

interface Props {
  children: React.ReactNode;
  title: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  nav: {
    listStyle: "none",
    padding: 0,
    margin: 0,
  },
  title: {
    color: theme.palette.common.white,
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
}));

export const WebFooterNav: React.FunctionComponent<Props> = (
  props: Props,
) => {
  const classes = useStyles();

  return (
    <ul className={classes.nav}>
      <Typography variant="subtitle1" className={classes.title}>
        {props.title}
      </Typography>
      {props.children}
    </ul>
  );
};
