import {apiRequest} from "core/services/api";
import {ApiClientMethod} from "core/utils/api-client";
import * as React from "react";
import {useParent} from "web/common/hooks/use-parent";

export interface ParentScopesContext {
  scopes: Set<string>;
}

const ctx = React.createContext<ParentScopesContext>({
  scopes: new Set(),
});

interface Props {
  children: React.ReactNode;
}

export function useParentScopes(): Set<string> {
  return React.useContext(ctx).scopes;
}

const ParentScopesProviderComponent: React.FunctionComponent<Props> = (
  props: Props,
) => {
  const parent = useParent();
  const [scopes, setScopes] = React.useState(new Set<string>());

  React.useEffect(() => {
    apiRequest({
      method: ApiClientMethod.GET,
      url: `v2/user/roles/${parent.id}`,
      onLoadStart: () => setScopes(new Set()),
      onSuccess: (body: {scopes: string[]}) =>
        setScopes(new Set(body?.scopes || [])),
    });
  }, [parent.id, setScopes]);

  const value = React.useMemo(() => ({scopes}), [scopes]);

  return <ctx.Provider value={value}>{props.children}</ctx.Provider>;
};

export const ParentScopesProvider = React.memo(
  ParentScopesProviderComponent,
);
