import * as React from "react";

interface Props extends React.HTMLAttributes<SVGElement> {
  fill?: string;
  height?: string;
  style?: React.StyleHTMLAttributes<SVGElement>;
}

export const PatreonIcon: React.FunctionComponent<Props> = (
  props: Props,
) => {
  const {
    fill = "#9046FF",
    height = "100px",
    style = {},
    ...extraProps
  } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      height={height}
      style={{fill, ...style}}
      {...extraProps}
    >
      <g fillRule="evenodd">
        <path
          d="M500,0L0,500v1800h600v500l500-500h400l900-900V0H500z M2200,1300l-400,400h-400l-350,350v-350H600V200h1600
				V1300z"
        />
        <path d="M64.1102,0.1004 C44.259,0.1004 28.1086,16.2486 28.1086,36.0986 C28.1086,55.8884 44.259,71.989 64.1102,71.989 C83.9,71.989 100,55.8884 100,36.0986 C100,16.2486 83.9,0.1004 64.1102,0.1004" />
        <polygon points=".012 95.988 17.59 95.988 17.59 .1 .012 .1" />
      </g>
    </svg>
  );
};
