export function setError(
  state: {error: string | null},
  payload: string | null,
) {
  return {...state, error: payload};
}

export function setModalError(
  state: {modalError: string},
  payload: string,
) {
  return {...state, modalError: payload};
}

export function incrementLoading(state: {loading: number}) {
  return {...state, loading: state.loading + 1};
}

export function decrementLoading(state: {loading: number}) {
  return {...state, loading: state.loading - 1};
}

export function incrementSubmitting(state: {submitting: number}) {
  return {...state, submitting: state.submitting + 1};
}

export function decrementSubmitting(state: {submitting: number}) {
  return {...state, submitting: state.submitting - 1};
}

export const commonReducers = {
  setError,
  incrementLoading,
  decrementLoading,
  incrementSubmitting,
  decrementSubmitting,
  setModalError,
};
