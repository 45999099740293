import * as React from "react";
import {MainNav} from "../main-nav";
import {WebFooter} from "web/components/web-footer";
import {Helmet} from "react-helmet";

interface Props {
  children: React.ReactNode;
}

export const MainLayout: React.FunctionComponent<Props> = (
  props: Props,
) => {
  return (
    <div>
      <Helmet>
        <title>
          Fossabot | Ultimate FREE, cloud hosted Twitch chat bot
        </title>
      </Helmet>
      <MainNav />
      {props.children}
      <WebFooter />
    </div>
  );
};
