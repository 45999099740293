import * as React from "react";
import * as classNames from "classnames";
import * as qs from "query-string";
import {Link, useLocation} from "react-router-dom";
import {Channel} from "common/models";
import {Button, makeStyles, Theme} from "@material-ui/core";
import {TwitchGlitch} from "web/common/components/brands/twitch-glitch";

interface Props {
  authenticated: boolean;
  channel: Channel | null;
}

declare const process: {
  env: {
    REACT_APP_API_BASE: string;
  };
};

const API_BASE = process.env.REACT_APP_API_BASE;

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: "#fff",
  },
  buttonContent: {
    display: "flex",
    alignItems: "center",
  },
  loginLabel: {
    paddingLeft: theme.spacing(1),
  },
  twitchButton: {
    backgroundColor: "#9046FF",
    "&:hover, &:focus": {
      backgroundColor: "#7c25ff",
    },
  },
}));

export const HomeHeroCTA: React.FunctionComponent<Props> = (
  props: Props,
) => {
  const classes = useStyles();
  const routeLocation = useLocation();

  if (props.authenticated) {
    if (!props.channel) {
      return null;
    }

    return (
      <Button
        color="secondary"
        variant="contained"
        component={Link}
        to={`/c/${encodeURIComponent(props.channel.slug)}/dashboard`}
      >
        Open Dashboard
      </Button>
    );
  }

  const onClick = () => {
    let url = API_BASE + "auth/twitch/redirect";
    const query = qs.parse(routeLocation.search);
    if (query.next) {
      url += `?next=${encodeURIComponent(query.next.toString())}`;
    }
    window.location.href = url;
  };

  return (
    <Button
      className={classNames(classes.button, classes.twitchButton)}
      variant="contained"
      onClick={onClick}
    >
      <span className={classes.buttonContent}>
        <TwitchGlitch fill="#fff" height="20px" />
        <span className={classes.loginLabel}>Login with Twitch</span>
      </span>
    </Button>
  );
};
