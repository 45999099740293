import {createModel} from "@rematch/core";
import {Channel, User, UserProviderTokens} from "common/models";
import {app} from "core/app";
import {apiRequest} from "core/services/api";
import {setTwitchApiToken} from "core/services/twitch-api";
import {commonReducers} from "core/store/helpers";
import {ApiClientMethod} from "core/utils/api-client";
import {RequestError} from "core/utils/request-error";
import {userSession} from "core/utils/user-session";

export interface UserState {
  authorized: boolean | null;
  loading: number;
  error: string | null;
  user: User | null;
  userChannel: Channel | null;
  hasToken: boolean;
}

export const userStore = createModel({
  state: {
    authorized: null,
    loading: 0,
    error: null,
    user: null,
    userChannel: null,
    hasToken: false,
  },

  reducers: {
    ...commonReducers,
    setUser: (state: UserState, payload: User) => ({
      ...state,
      error: null,
      authorized: !!payload,
      user: payload,
      userChannel: null,
    }),

    setChannel: (state: UserState, payload: Channel) => ({
      ...state,
      error: null,
      userChannel: payload,
    }),

    setHasToken: (state: UserState) => ({...state, hasToken: true}),
  },

  effects: () => ({
    getUser(_, state: {userStore: UserState}) {
      if (!app.session.hasToken()) {
        return this.setUser(null);
      }

      if (state.userStore.user) {
        return;
      }

      this.getUserTokens();
      this.refreshUser();
    },

    getUserTokens(done?: () => void) {
      // done && done();
      apiRequest({
        method: ApiClientMethod.GET,
        url: "v2/user/provider-tokens",
        onLoadStart: this.incrementLoading,
        onLoadEnd: this.decrementLoading,
        onSuccess: (user: UserProviderTokens) => {
          setTwitchApiToken(user.access_token, user.client_id);
          this.setHasToken();
          done && done();
        },
        onError: (error: RequestError) => {
          console.error("Failed to get user provider tokens", error);
          this.setUser(null);
          app.session.deleteToken();
        },
      });
    },

    refreshUser() {
      apiRequest({
        method: ApiClientMethod.GET,
        url: "v2/user",
        onLoadStart: this.incrementLoading,
        onLoadEnd: this.decrementLoading,
        onSuccess: (user: User) => {
          this.setUser(user);
        },
        onError: (error: RequestError) => {
          if (!error.statusCode || error.statusCode === 401) {
            userSession.deleteToken();
            return this.setUser(null);
          }
          return this.setError(error);
        },
      });
    },

    getUserChannelIfNeeded(_, state: {userStore: UserState}) {
      if (!state.userStore.user || state.userStore.userChannel) {
        return;
      }

      apiRequest({
        method: ApiClientMethod.GET,
        query: {id: state.userStore.user.channel_id},
        url: "v2/channels",
        onLoadStart: this.incrementLoading,
        onLoadEnd: this.decrementLoading,
        onSuccess: this.setChannel,
        onError: this.setError,
      });
    },

    logout() {
      this.setUser(null);
      app.session.deleteToken();
    },
  }),
});
