import * as React from "react";
import {useSnackbar, OptionsObject} from "notistack";
import {app} from "core/app";

export const SnackbarTransporter: React.FunctionComponent = () => {
  const snackbar = useSnackbar();

  const handleEvent = React.useCallback(
    (message: React.ReactNode, options?: OptionsObject) => {
      snackbar.enqueueSnackbar(message, options);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  React.useEffect(() => {
    app.snackbarUtil.addEventListener(handleEvent);
    return () => app.snackbarUtil.removeEventListener(handleEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
