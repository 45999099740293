export enum FilterType {
  Caps = "caps",
  Actions = "actions",
  Links = "links",
  Emotes = "emotes",
  Length = "length",
  Repetition = "repetition",
  Symbols = "symbols",
  Solospam = "solospam",
  Zalgo = "zalgo",
  EmoteExploit = "emote_exploit",
  R9k = "r9k",
  MessageHeight = "message_height",
  // Commands = "commands",
  Locale = "locale",
}

export interface BaseFilter<T extends FilterType, S = {}> {
  id: string;
  name: string;
  reason: string;
  type: T;
  allowed_keywords: string[][];
  blocked_keywords: string[][];
  allowed_role_ids: string[];
  blocked_role_ids: string[];
  enabled: boolean;
  enabled_offline: boolean;
  enabled_online: boolean;
  permittable: boolean;
  announcement: FilterAnnouncement;
  repeat_offender: FilterRepeatOffender;
  sanction: FilterSanction;
  warning: FilterWarning;
  settings: S;
  created_at: string;
  updated_at: string;
}

export interface FilterAnnouncement {
  enabled: boolean;
  cooldown: number;
}

export interface FilterRepeatOffender {
  enabled: boolean;
  cooldown: number;
  multiplier: number;
}

export interface FilterSanction {
  base: number;
  max: number;
}

export interface FilterWarning {
  enabled: boolean;
  duration: number;
}

export enum CapsFilterType {
  Absolute = "absolute",
  Percent = "percent",
}

export interface CapsFilterSettings {
  mode: CapsFilterType;
  min_chars: number;
  max: number;
}

export type CapsFilter = BaseFilter<
  typeof FilterType.Caps,
  CapsFilterSettings
>;

export interface LinksFilterClipsSettings {
  allowlisted_channels: string[];
  enabled: boolean;
  owner_clips: boolean;
  external_clips: boolean;
}

export interface LinksFilterSettings {
  allowlist: string[];
  blocklist: string[];
  clips: LinksFilterClipsSettings;
}

export type LinksFilter = BaseFilter<
  typeof FilterType.Links,
  LinksFilterSettings
>;

export interface ActionsFilterSettings {}

export type ActionsFilter = BaseFilter<
  typeof FilterType.Actions,
  ActionsFilterSettings
>;

export interface EmotesFilterSettings {
  min_chars: number;
  max: number;
  enabled_bttv: boolean;
  enabled_ffz: boolean;
  enabled_provider: boolean;
}

export type EmotesFilter = BaseFilter<
  typeof FilterType.Emotes,
  EmotesFilterSettings
>;

export interface LengthFilterSettings {
  max: number;
}

export type LengthFilter = BaseFilter<
  typeof FilterType.Length,
  LengthFilterSettings
>;

export interface RepetitionFilterSettings {
  min_chars: number;
  max: number;
}

export type RepetitionFilter = BaseFilter<
  typeof FilterType.Repetition,
  RepetitionFilterSettings
>;

// export interface CommandsFilterSettings {
//   enabled_custom: boolean;
//   enabled_default: boolean;
//   allowed_commands: string[];
// }

// export type CommandsFilter = BaseFilter<
//   typeof FilterType.Commands,
//   CommandsFilterSettings
// >;

export enum SymbolsFilterType {
  Absolute = "absolute",
  Percent = "percent",
}

export interface SymbolsFilterSettings {
  mode: SymbolsFilterType;
  min_chars: number;
  max: number;
}

export type SymbolsFilter = BaseFilter<
  typeof FilterType.Symbols,
  SymbolsFilterSettings
>;

export interface SolospamFilterSettings {
  seconds: number;
  min_messages: number;
  min_chars: number;
  max: number;
}

export type SolospamFilter = BaseFilter<
  typeof FilterType.Solospam,
  SolospamFilterSettings
>;

export interface ZalgoFilterSettings {
  min_chars: number;
}

export type ZalgoFilter = BaseFilter<
  typeof FilterType.Zalgo,
  ZalgoFilterSettings
>;

export interface EmoteExploitFilterSettings {
  must_be_chatter: boolean;
}

export type EmoteExploitFilter = BaseFilter<
  typeof FilterType.EmoteExploit,
  EmoteExploitFilterSettings
>;

export interface LocaleFilterSettings {
  enabled_arabic: number;
  enabled_armenian: number;
}

export type LocaleFilter = BaseFilter<
  typeof FilterType.Locale,
  LocaleFilterSettings
>;

export interface R9kFilterSettings {
  min_chars: number;
  seconds: number;
  max: number;
}

export type R9kFilter = BaseFilter<
  typeof FilterType.R9k,
  R9kFilterSettings
>;

export interface MessageHeightFilterSettings {
  min_chars: number;
  max: number;
}

export type MessageHeightFilter = BaseFilter<
  typeof FilterType.MessageHeight,
  MessageHeightFilterSettings
>;

export type FilterSettings =
  | ActionsFilterSettings
  | CapsFilterSettings
  | LinksFilterSettings
  | EmotesFilterSettings
  | LengthFilterSettings
  | RepetitionFilterSettings
  // | CommandsFilterSettings
  | SolospamFilterSettings
  | SymbolsFilterSettings
  | ZalgoFilterSettings
  | EmoteExploitFilterSettings
  | LocaleFilterSettings
  | R9kFilterSettings;
// | MessageHeightFilterSettings;

export type Filter =
  | ActionsFilter
  | CapsFilter
  | LinksFilter
  | EmotesFilter
  | LengthFilter
  | RepetitionFilter
  // | CommandsFilter
  | SolospamFilter
  | SymbolsFilter
  | ZalgoFilter
  | EmoteExploitFilter
  | LocaleFilter
  | R9kFilter;
// | MessageHeightFilter;
