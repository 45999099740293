import {connect} from "react-redux";
import {Dispatch, RootState} from "core/store/store";
import {
  ReduxDispatchProps,
  ReduxStateProps,
  PublicProps,
  DashboardLayoutComponent,
} from "./component";

function mapStateToProps(state: RootState): ReduxStateProps {
  return {
    channel: state.channelStore.channel,
    parent: state.parentStore.parent,
    userAuthorized: state.userStore.authorized,
  };
}

function mapDispatchToProps(dispatch: Dispatch): ReduxDispatchProps {
  return {
    loadChannel: dispatch.channelStore.getChannel,
    loadParent: dispatch.parentStore.getParent,
    loadUser: dispatch.userStore.getUser,
  };
}

export const DashboardLayout: React.FunctionComponent<PublicProps> = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardLayoutComponent);
