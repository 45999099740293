import * as React from "react";
import {makeStyles, Theme} from "@material-ui/core";
import {CenterProgress} from "web/common/components/center-progress";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.grey[900],
    width: "100vw",
    height: "100vh",
    position: "fixed",
    left: 0,
    top: 0,
    display: "flex",
    alignItems: "center",
  },
}));

export const LegalLoader: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <CenterProgress />
    </div>
  );
};
