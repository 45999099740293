import {List} from "@material-ui/core";
import {
  AccessTime,
  Apps,
  Block,
  Pageview,
  PriorityHigh,
  SpeakerNotesOff,
  SupervisedUserCircle,
  Tune,
} from "@material-ui/icons";
import * as React from "react";
import {useRouteMatch} from "react-router-dom";
import {DashboardSidebarSection} from "../../models/dashboard-sidebar";
import {DashboardSidebarGroup} from "../dashboard-sidebar-group/component";

const items: DashboardSidebarSection[] = [
  {
    title: "Main",
    items: [
      {
        url: "dashboard",
        icon: Apps,
        label: "Dashboard",
        scopes: [],
      },
      {
        url: "commands",
        icon: PriorityHigh,
        label: "Commands",
        scopes: [],
      },
      {
        url: "keywords",
        icon: Pageview,
        label: "Keywords",
        scopes: [],
      },
      {
        url: "timers",
        icon: AccessTime,
        label: "Timers",
        scopes: [],
      },
    ],
  },

  {
    title: "Moderation",
    items: [
      {
        url: "filters",
        icon: Block,
        label: "Spam filters",
        scopes: [],
      },
      {
        url: "blocked-terms",
        icon: SpeakerNotesOff,
        label: "Blocked terms",
        scopes: [],
      },
    ],
  },
  // {
  //   title: "Vanity",
  //   items: [
  //     {
  //       url: "modules",
  //       icon: Memory,
  //       label: "Modules",
  //       scopes: [],
  //     },
  //     {
  //       url: "song-request",
  //       icon: QueueMusic,
  //       label: "Song request",
  //       scopes: [],
  //     },
  //     {
  //       url: "giveaways",
  //       icon: CardGiftcard,
  //       label: "Giveaways",
  //       scopes: [],
  //     },
  //     {
  //       url: "widgets",
  //       icon: Dashboard,
  //       label: "Widgets",
  //       scopes: [],
  //     },
  //     {
  //       url: "integrations",
  //       icon: Extension,
  //       label: "Integrations",
  //       scopes: [],
  //     },
  //   ],
  // },
  {
    title: "Management",
    items: [
      {
        url: "roles",
        icon: SupervisedUserCircle,
        label: "User Roles",
        scopes: [],
      },
      {
        url: "settings",
        icon: Tune,
        label: "Channel Settings",
        scopes: [],
      },
    ],
  },
];

function checkSidebarScopes(
  section: DashboardSidebarSection,
): DashboardSidebarSection | null {
  const filteredItems = section.items.filter(() => {
    return true;
  });
  if (filteredItems.length === 0) {
    return null;
  }
  return {...section, items: filteredItems};
}

const DashboardSidebarNavComponent: React.FunctionComponent = () => {
  const match = useRouteMatch<{parent: string}>();
  const basePath = `${match.path.substr(0, 2)}/${encodeURIComponent(
    match.params.parent,
  )}/`;

  return (
    <List component="nav">
      {items.map((item: DashboardSidebarSection | null) => {
        item = checkSidebarScopes(item!);
        if (!item) return null;
        return (
          <DashboardSidebarGroup
            basePath={basePath}
            key={item.title}
            title={item.title}
            items={item.items}
            scopes={item.scopes || []}
          />
        );
      })}
    </List>
  );
};

export const DashboardSidebarNav = React.memo(
  DashboardSidebarNavComponent,
);
