import {connect} from "react-redux";
import {Dispatch} from "core/store/store";
import {
  ReduxDispatchProps,
  PublicProps,
  AppRootComponent,
} from "./component";

function mapDispatchToProps(dispatch: Dispatch): ReduxDispatchProps {
  return {getUser: dispatch.userStore.getUser};
}

export const AppRoot: React.FunctionComponent<PublicProps> = connect(
  undefined,
  mapDispatchToProps,
)(AppRootComponent);
