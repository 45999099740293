import {createModel} from "@rematch/core";
import {AppTheme} from "common/models";

export interface UserSettingsState {
  theme: AppTheme;
}

export const userSettingsStore = createModel({
  state: {
    theme: AppTheme.Light,
  },

  reducers: {
    updateTheme: (state: UserSettingsState, payload: AppTheme) => ({
      ...state,
      theme: payload,
    }),
  },

  effects: () => ({
    setTheme(theme: AppTheme) {
      return this.updateTheme(theme);
    },
  }),
});
