import * as React from "react";
import {Switch, Route, Redirect} from "react-router-dom";
import {MainLayout} from "../main-layout";
import {MuiBaseComponent} from "web/components/mui-base/component";
import {AppTheme} from "common/models";
import {Home} from "../../pages/home";
import {Loadable} from "web/common/components/loadable";
import {LegalLoader} from "web/main/pages/legal/components/legal-loader";

const PrivacyPolicy = Loadable(
  () =>
    import(
      /* webpackChunkName: "main.pages.legal.privacy-policy" */ "../../pages/legal/pages/privacy-policy"
    ),
  "PrivacyPolicy",
  LegalLoader,
);

const TermsOfService = Loadable(
  () =>
    import(
      /* webpackChunkName: "main.pages.legal.privacy-policy" */ "../../pages/legal/pages/terms-of-service"
    ),
  "TermsOfService",
  LegalLoader,
);

const legalRoutes: React.FunctionComponent = () => {
  return (
    <MuiBaseComponent theme={AppTheme.Dark}>
      <Switch>
        <Route path="/legal/privacy" component={PrivacyPolicy} />
        <Route path="/legal/terms" component={TermsOfService} />
        <Redirect from="*" to="/" />
      </Switch>
    </MuiBaseComponent>
  );
};

const otherRoutes: React.FunctionComponent = () => {
  return (
    <>
      <MuiBaseComponent theme={AppTheme.Light}>
        <MainLayout>
          <Switch>
            <Route exact path="/" component={Home} />
          </Switch>
        </MainLayout>
      </MuiBaseComponent>
    </>
  );
};

export const Main: React.FunctionComponent = () => {
  return (
    <Switch>
      <Route path="/legal" component={legalRoutes} />
      <Route path="*" component={otherRoutes} />
    </Switch>
  );
};
