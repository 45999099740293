import * as React from "react";

interface Props extends React.HTMLAttributes<SVGElement> {
  fill?: string;
  height?: string;
  style?: React.StyleHTMLAttributes<SVGElement>;
}

export const DiscordIcon: React.FunctionComponent<Props> = (
  props: Props,
) => {
  const {
    fill = "#9046FF",
    height = "100px",
    style = {},
    ...extraProps
  } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      height={height}
      style={{fill, ...style}}
      {...extraProps}
    >
      <path d="M10.652 10.532c0-.488.36-.88.815-.88.448 0 .816.392.816.88 0 .487-.36.88-.816.88-.448 0-.815-.393-.815-.88zM7.733 10.532c0-.488.36-.88.816-.88.456 0 .823.392.815.88 0 .487-.36.88-.815.88-.448 0-.816-.393-.816-.88z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.64 3h10.713c.903 0 1.639.736 1.647 1.64v14.215l-1.727-1.495-.967-.888-1.024-.943.424 1.463H4.639c-.903 0-1.639-.736-1.639-1.64V4.64C3 3.736 3.736 3 4.64 3zm7.099 9.714c.24.296.528.64.528.64 1.674-.053 2.365-1.09 2.433-1.191l.005-.008c0-2.55-1.151-4.622-1.151-4.622-1.143-.855-2.239-.831-2.239-.831l-.112.128c1.36.408 1.991 1.007 1.991 1.007a6.647 6.647 0 00-2.407-.76 6.765 6.765 0 00-1.615.017.673.673 0 00-.118.013l-.017.003c-.28.032-.96.128-1.815.503-.296.128-.472.224-.472.224s.655-.631 2.095-1.04l-.08-.095s-1.088-.024-2.239.831c0 0-1.151 2.071-1.151 4.622 0 0 .671 1.151 2.438 1.207 0 0 .296-.352.536-.655-1.015-.304-1.4-.936-1.4-.936s.08.056.225.136c.005 0 .01.003.016.007l.016.009c.012.008.024.014.036.02a4.682 4.682 0 00.62.292c.327.136.719.256 1.175.344a5.6 5.6 0 003.23-.336c.28-.104.591-.256.92-.472 0 0-.4.648-1.448.943z"
      />
    </svg>
  );
};
