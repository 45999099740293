import {
  AppBar,
  Drawer,
  Hidden,
  makeStyles,
  Theme,
  Toolbar,
} from "@material-ui/core";
import * as React from "react";
import {Link, useLocation} from "react-router-dom";
import {BrandLogo} from "web/common/components/brand-logo";
import {DashboardManager} from "../dashboard-manager";
import {DashboardSidebarNav} from "../dashboard-sidebar/components/dashboard-sidebar-nav";
import {TopBarSidebarToggle} from "../top-bar-sidebar-toggle";
import {TopBarThemeToggle} from "../top-bar-theme-toggle";
import {TopNavUserBox} from "../top-nav-user-box";

const useStyles = makeStyles((theme: Theme) => ({
  bar: {
    backgroundColor: theme.palette.grey[900],
  },
  toolbar: {
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-between",
    },
  },
  drawerWrapper: {
    padding: theme.spacing(3),
    width: "270px",
  },
}));

const TopBarComponent: React.FunctionComponent = () => {
  const classes = useStyles();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  React.useEffect(() => {
    setOpen(false);
  }, [location.pathname]);

  return (
    <AppBar elevation={0} position="relative" className={classes.bar}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <TopBarSidebarToggle open={open} setOpen={setOpen} />
          <Drawer open={open} onClose={handleClose}>
            <div className={classes.drawerWrapper}>
              <DashboardManager />
              <DashboardSidebarNav />
            </div>
          </Drawer>
        </Hidden>
        <Link to="/">
          <BrandLogo />
        </Link>
        <Hidden mdDown>
          <TopBarThemeToggle />
        </Hidden>
        <TopNavUserBox />
      </Toolbar>
    </AppBar>
  );
};

export const TopBar = React.memo(TopBarComponent);
