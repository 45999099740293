import {useEffect, useState} from "react";

const imageCache = new Set<string>();

function purgeCache() {
  while (imageCache.size > 100) {
    imageCache.delete(imageCache.values().next().value);
  }
}

export function useImageLoaded(url?: string | null) {
  const [loaded, setLoaded] = useState(true);

  useEffect(() => {
    if (!url || imageCache.has(url)) {
      return;
    }
    setLoaded(false);

    const img = new Image();

    img.onload = () => {
      setLoaded(true);
      requestAnimationFrame(() => {
        imageCache.add(url);
        purgeCache();
      });
    };
    img.src = url;

    return () => {
      img.onload = null;
    };
  }, [url]);

  return (!!url && imageCache.has(url)) || loaded;
}
