import * as React from "react";
import {HomeHero} from "./components/home-hero";
import {HomeTrustedStreamers} from "./components/home-trusted-streamers";
import {HomeFeatures} from "./components/home-features";

export const Home: React.FunctionComponent = () => {
  return (
    <>
      <HomeHero />
      <HomeFeatures />
      <HomeTrustedStreamers />
    </>
  );
};
