import {makeStyles, Theme} from "@material-ui/core";
import {Channel, Parent, ParentType} from "common/models";
import * as React from "react";
import {Helmet} from "react-helmet";
import {Redirect, useRouteMatch} from "react-router-dom";
import {DashboardLoader} from "web/dashboard/components/dashboard-loader";
import {DashboardSidebar} from "../dashboard-sidebar";
import {TopBar} from "../top-bar";

export interface ReduxStateProps {
  channel: Channel | null;
  parent: Parent | null;
  userAuthorized: boolean | null;
}

export interface ReduxDispatchProps {
  loadChannel(slug: string): void;
  loadUser(): void;
  loadParent(payload: {type: ParentType; id: string}): void;
}

export interface PublicProps {
  children: React.ReactNode;
}

type Props = ReduxStateProps & ReduxDispatchProps & PublicProps;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    minWidth: 0,
    minHeight: 0,
    overflowY: "auto",
    backgroundColor: theme.palette.background.paper,
    flex: 1,
    [theme.breakpoints.down("md")]: {
      // paddingLeft: theme.spacing(3),
      // paddingRight: theme.spacing(3),
    },
  },
  bottom: {
    top: theme.spacing(8),
    bottom: 0,
    width: "100vw",
    display: "flex",
    minHeight: "0",
    flex: 1,
    flexDirection: "row",
    overflowY: "auto",
    [theme.breakpoints.down("xs")]: {
      top: theme.spacing(6),
    },
  },
}));

export const DashboardLayoutComponent: React.FunctionComponent<Props> = (
  props: Props,
) => {
  const classes = useStyles();
  const match = useRouteMatch<{parent: string}>();
  const {channel, loadChannel, loadParent, loadUser} = props;
  const {parent} = match.params;

  React.useEffect(() => {
    loadUser();
  }, [loadUser]);

  const path = match.path.toLowerCase();
  const isChannel = path.startsWith("/c/");
  const isPreset = path.startsWith("/p/");
  const isValid = isChannel || isPreset;

  React.useEffect(() => {
    isChannel && loadChannel(parent);
  }, [isChannel, parent, loadChannel]);

  React.useEffect(() => {
    channel && loadParent({type: ParentType.Channel, id: channel.id});
  }, [channel, loadParent]);

  if (!isValid) {
    return (
      <Redirect
        to={`/${isChannel ? "c" : "p"}/${encodeURIComponent(
          match.params.parent,
        )}/dashboard`}
      />
    );
  }

  return (
    <div className={classes.container}>
      <Helmet titleTemplate="%s | Fossabot" />
      <TopBar />
      <div className={classes.bottom}>
        <DashboardSidebar />
        <div className={classes.content}>
          {props.parent ? props.children : <DashboardLoader />}
        </div>
      </div>
    </div>
  );
};
