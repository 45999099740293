import * as React from "react";
import * as cx from "classnames";
import {makeStyles, Theme, Typography} from "@material-ui/core";

interface Props {
  title: string;
  subtitle?: string;
  isHome?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(12),
    margin: "0 auto",
    maxWidth: "685px",
  },
  containerBottom: {
    paddingBottom: theme.spacing(12),
  },
  title: {
    lineHeight: 1.25,
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(36),
    color: theme.palette.common.white,
  },
  subtitle: {
    color: theme.palette.common.white,
    fontWeight: 500,
    opacity: 0.9,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    fontSize: theme.typography.pxToRem(16),
  },
}));

export const MainHeroText: React.FunctionComponent<Props> = (
  props: Props,
) => {
  const classes = useStyles();

  return (
    <div
      className={cx(
        classes.container,
        !props.isHome && classes.containerBottom,
      )}
    >
      <Typography className={classes.title} align="center">
        {props.title}
      </Typography>
      {!!props.subtitle && (
        <Typography className={classes.subtitle} align="center">
          {props.subtitle}
        </Typography>
      )}
    </div>
  );
};
