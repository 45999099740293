import {createModel} from "@rematch/core";
import {AuditLog} from "common/models";
import {commonReducers} from "core/store/helpers";
import {apiRequest} from "core/services/api";
import {ApiClientMethod} from "core/utils/api-client";
import {twitchUserCache} from "core/utils/twitch-user-cache";
import {TwitchUser} from "common/models/twitch";
// import {twitchGenerateDefaultAvatar} from "core/utils/twitch";

export interface AuditLogStore {
  channelID: string | null;
  logs: AuditLog[];
  loading: number;
  error: string | null;
}

const initialState: AuditLogStore = {
  channelID: null,
  logs: [],
  loading: 0,
  error: null,
};

export const auditLogStore = createModel({
  state: initialState,

  reducers: {
    ...commonReducers,

    setLogs: (
      state: AuditLogStore,
      payload: {channelID: string; logs: AuditLog[]},
    ) => ({
      ...state,
      channelID: payload.channelID,
      logs: payload.logs,
    }),
  },

  effects: () => ({
    getLogs(channel: string) {
      apiRequest({
        method: ApiClientMethod.GET,
        url: `v2/audits/parents/${encodeURIComponent(channel)}`,
        onLoadStart: this.incrementLoading,
        onLoadEnd: this.decrementLoading,
        onError: this.setError,
        onSuccess: (body: {data: AuditLog[]}) => {
          const logs = body.data;
          const ids = logs.map((log) => log.user.provider_id);
          twitchUserCache
            .getIDs(ids)
            .then((users: Map<string, TwitchUser>) => {
              for (let i = 0; i < logs.length; ++i) {
                const log = logs[i];
                const twitchUser = users.get(log.user.provider_id);
                if (twitchUser) {
                  log.user = {
                    avatar: twitchUser.profile_image_url,
                    display_name: twitchUser.display_name,
                    provider: "twitch",
                    provider_id: twitchUser.id,
                  };
                  logs[i] = log;
                }
              }

              return this.setLogs({
                channel: channel,
                logs: logs,
              });
            });
        },
      });
    },
  }),
});
