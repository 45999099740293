import {
  Grid,
  makeStyles,
  SvgIconProps,
  Theme,
  Typography,
} from "@material-ui/core";
import * as React from "react";

interface Props {
  description: string;
  icon: React.ComponentType<SvgIconProps>;
  label: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    display: "block",
    margin: `${theme.spacing(0.4)}px auto`,
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  description: {
    lineHeight: 1.6,
  },
}));

export const HomeFeatureItem: React.FunctionComponent<Props> = (
  props: Props,
) => {
  const classes = useStyles();
  const Icon = props.icon;

  return (
    <Grid container>
      <Grid item xs={2}>
        <Icon
          color="primary"
          width={32}
          height={32}
          className={classes.icon}
        />
      </Grid>
      <Grid item xs>
        <Typography
          variant="h6"
          className={classes.title}
          gutterBottom
        >
          {props.label}
        </Typography>
        <Typography variant="body1" className={classes.description}>
          {props.description}
        </Typography>
      </Grid>
    </Grid>
  );
};
