import * as EventEmitter from "eventemitter3";
import * as React from "react";
import {OptionsObject} from "notistack";

const EVENT_KEY = "e";

export class SnackbarUtil {
  private eventEmitter = new EventEmitter();

  public addEventListener(
    cb: (message: React.ReactNode, options?: OptionsObject) => void,
  ) {
    this.eventEmitter.on(EVENT_KEY, cb);
  }

  public removeEventListener(
    cb: (message: React.ReactNode, options?: OptionsObject) => void,
  ) {
    this.eventEmitter.off(EVENT_KEY, cb);
  }

  public enqueueSnackbar(
    message: React.ReactNode,
    options?: OptionsObject,
  ) {
    this.eventEmitter.emit(EVENT_KEY, message, options);
  }
}
