import * as React from "react";
import cx from "classnames";
import {makeStyles, Theme, Switch} from "@material-ui/core";
import {Brightness5, Brightness4} from "@material-ui/icons";
import {AppTheme} from "common/models";

export interface ReduxStateProps {
  theme: AppTheme;
}

export interface ReduxDispatchProps {
  changeTheme(theme: AppTheme): void;
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "auto",
  },
  iconWrapper: {
    display: "inline-block",
    opacity: 0.5,
    height: theme.typography.pxToRem(22),
    transition: "opacity .15s ease-in-out",
  },
  selected: {
    opacity: 1,
  },
  icon: {
    fontSize: `${theme.typography.pxToRem(22)} !important`,
  },
}));

type Props = ReduxStateProps & ReduxDispatchProps;

export const TopBarThemeToggleComponent: React.FunctionComponent<Props> = (
  props: Props,
) => {
  const classes = useStyles();

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    props.changeTheme(
      event.target.checked ? AppTheme.Dark : AppTheme.Light,
    );
  };

  return (
    <div className={classes.wrapper}>
      <span
        className={cx(
          classes.iconWrapper,
          props.theme === AppTheme.Light && classes.selected,
        )}
      >
        <Brightness5 />
      </span>
      <Switch
        checked={props.theme === AppTheme.Dark}
        value="true"
        color="primary"
        onChange={handleChange}
      />
      <span
        className={cx(
          classes.iconWrapper,
          props.theme === AppTheme.Dark && classes.selected,
        )}
      >
        <Brightness4 />
      </span>
    </div>
  );
};

// const useStyles = makeStyles(() => createStyles({
//
// }));

// const ThemeSwitcher: React.FunctionComponent<IProps> = ({ setTheme, theme }) => {
//   const classes = useStyles({});

//   return (
//     <div className={classes.wrapper}>
//       <span className={cx(classes.iconWrapper, theme === AppTheme.light ? classes.iconSelected : undefined)}>
//         <Icon className={classes.icon}>brightness_5</Icon>
//       </span>
//       <Switch
//         checked={theme === AppTheme.dark}
//         value="true"
//         color="primary"
//         onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
//           setTheme(event.target.checked ? AppTheme.dark : AppTheme.light);
//         }}
//       />
//       <span className={cx(classes.iconWrapper, theme === AppTheme.dark ? classes.iconSelected : undefined)}>
//         <Icon className={classes.icon}>brightness_4</Icon>
//       </span>
//     </div>
//   );
// };
