import * as React from "react";
import {Helmet} from "react-helmet";
import {SnackbarProvider} from "notistack";
import DateFnsUtils from "@date-io/date-fns";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import {MuiBase} from "../mui-base";

import seoImage from "assets/images/fossabot-1024.png";

export interface ReduxDispatchProps {
  getUser(): void;
}

export interface PublicProps {
  children: React.ReactNode;
}

type Props = PublicProps & ReduxDispatchProps;

const DESCRIPTION =
  "Fossabot is the ultimate Twitch chat bot that has all the features you need to create the best chat experience for your audience.";

export const AppRootComponent: React.FunctionComponent<Props> = ({
  getUser,
  children,
}: Props) => {
  React.useEffect(() => {
    getUser();
  }, [getUser]);

  return (
    <MuiBase>
      <>
        <Helmet>
          <title>
            Fossabot | Ultimate FREE cloud hosted Twitch chat bot
          </title>
          <meta name="description" content={DESCRIPTION} />
          <meta property="og:image" content={seoImage} />
        </Helmet>
        <SnackbarProvider
          anchorOrigin={{horizontal: "right", vertical: "bottom"}}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {children}
          </MuiPickersUtilsProvider>
        </SnackbarProvider>
      </>
    </MuiBase>
  );
};
