import {RoleScope} from "common/models";
import * as React from "react";
import {DashboardScopeGuard} from "web/dashboard/components/dashboard-scope-guard";
import type {DashboardSidebarItem as DashboardSidebarItemModel} from "../../models/dashboard-sidebar";
import {DashboardSidebarHeader} from "../dashboard-sidebar-header";
import {DashboardSidebarItem} from "../dashboard-sidebar-item";

interface Props {
  basePath: string;
  title: string;
  scopes: RoleScope[];
  items: DashboardSidebarItemModel[];
}

const DashboardSidebarGroupComponent: React.FunctionComponent<Props> = (
  props: Props,
) => {
  return (
    <DashboardScopeGuard scope={props.scopes}>
      <DashboardSidebarHeader>{props.title}</DashboardSidebarHeader>
      {props.items.map((item) => (
        <DashboardSidebarItem
          key={item.url}
          external={item.external}
          url={props.basePath + item.url}
          label={item.label}
          icon={item.icon}
          scopes={item.scopes || []}
        />
      ))}
    </DashboardScopeGuard>
  );
};

export const DashboardSidebarGroup = React.memo(
  DashboardSidebarGroupComponent,
);
