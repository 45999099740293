import {
  createMuiTheme,
  CssBaseline,
  ThemeProvider,
} from "@material-ui/core";
import {blue, pink} from "@material-ui/core/colors";
import {AppTheme} from "common/models";
import * as React from "react";
import {ThemeManager} from "../theme-manager";

const typography = {
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "Noto Sans",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
    "Noto Color Emoji",
  ].join(","),
};

// main: paletteType === 'light' ? darken(pink.A400, 0.1) : pink[200],

const themeColors = {
  global: {
    primary: {
      main: blue["A200"],
    },
  },
  light: {
    background: {
      default: "#f3f3f3",
    },
  },
  dark: {
    secondary: {
      main: pink[200],
    },
  },
};

const themes = {
  [AppTheme.Light]: createMuiTheme({
    typography: typography,
    palette: {
      type: "light",
      ...themeColors.global,
      ...themeColors.light,
    },
  }),
  [AppTheme.Dark]: createMuiTheme({
    typography: typography,
    palette: {
      type: "dark",
      ...themeColors.global,
      ...themeColors.dark,
    },
  }),
};

export interface ReduxStateProps {
  theme: AppTheme;
}

export interface PublicProps {
  children: React.ReactNode;
}

type Props = PublicProps & ReduxStateProps;

export const MuiBaseComponent: React.FunctionComponent<Props> = (
  props: Props,
) => {
  return (
    <ThemeProvider theme={themes[props.theme]}>
      <CssBaseline />
      <ThemeManager />
      {props.children}
    </ThemeProvider>
  );
};
