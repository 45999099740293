import {makeStyles, Theme} from "@material-ui/core";
import * as React from "react";
import {DashboardManager} from "../../../dashboard-manager";
import {DashboardSidebarNav} from "../dashboard-sidebar-nav";

const useStyles = makeStyles((theme: Theme) => ({
  sidebar: {
    width: "270px",
    minWidth: "270px",
    padding: `${theme.spacing(4)}px ${theme.spacing(3)}px`,
    overflowY: "auto",
    borderRight: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

export const DashboardSidebar: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <aside className={classes.sidebar}>
      <DashboardManager />
      <DashboardSidebarNav />
    </aside>
  );
};
