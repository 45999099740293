import {createModel} from "@rematch/core";
import {Channel} from "common/models";
import {commonReducers} from "core/store/helpers";
import {apiRequest} from "core/services/api";
import {ApiClientMethod} from "core/utils/api-client";
import {twitchApiRequest} from "core/services/twitch-api";
import {
  TwitchChannel,
  TwitchHelixResponse,
} from "common/models/twitch";

export interface ChannelStore {
  accessible: boolean;
  channel: Channel | null;
  loading: number;
  error: string | null;
  game: string;
  title: string;
}

const initialState: ChannelStore = {
  accessible: true,
  channel: null,
  loading: 0,
  error: null,
  game: "",
  title: "",
};

export const channelStore = createModel({
  state: initialState,

  reducers: {
    ...commonReducers,
    resetState: () => initialState,
    setChannel: (state: ChannelStore, payload: Channel | null) => ({
      ...state,
      accessible: !!payload,
      channel: payload,
    }),
    setChannelMeta: (
      state: ChannelStore,
      payload: {title: string; game: string},
    ) => ({
      ...state,
      title: payload.title,
      game: payload.game,
    }),
  },

  effects: () => ({
    getChannel(slug: string) {
      apiRequest({
        method: ApiClientMethod.GET,
        query: {slug: slug},
        url: "v2/channels",
        onLoadStart: this.incrementLoading,
        onLoadEnd: this.decrementLoading,
        onSuccess: this.setChannel,
        onError: this.setError,
      });
    },

    getProviderMeta(channel: Channel) {
      const providerID = encodeURIComponent(channel.provider_id);
      if (channel.provider === "twitch") {
        twitchApiRequest({
          method: ApiClientMethod.GET,
          url: "https://api.twitch.tv/helix/channels",
          query: {broadcaster_id: providerID},
          onSuccess: (body: TwitchHelixResponse<TwitchChannel>) => {
            if (!body.data.length) {
              return;
            }
            const data = body.data[0];
            this.setChannelMeta({
              title: data.title,
              game: data.game_name,
            });
          },
        });
      }
    },

    reset() {
      return this.resetState();
    },

    setMeta() {
      //
    },
  }),
});
