import {Provider} from "./provider";

export interface RoleBody {
  name: string;
  scopes: RoleScope[];
  users: RoleUser[];
}

export interface ChatRole {
  id: string;
  name: string;
  muteable: boolean;
}

export interface Role extends RoleBody {
  id: string;
  type: RoleType;
  default: boolean;
  created_at: string;
  updated_at: string;
}

export interface RoleUser {
  id: string;
  provider: Provider;
  provider_id: string;
}

export enum RoleType {
  Custom = "custom",
  ProviderMod = "provider_mod",
  ProviderVIP = "provider_vip",
  ProviderFounder = "provider_founder",
  ProviderSubscriber = "provider_sub",
  ProviderBroadcaster = "provider_broadcaster",
}

export enum RoleScope {
  Admin = "admin:admin",
  AuditLogsView = "audit_logs:view",

  ChannelMetaTitle = "channel:meta:title",
  ChannelMetaGame = "channel:meta:game",

  BotJoin = "bot:join",
  BotPart = "bot:part",
  BotMute = "bot:mute",
  BotUnmute = "bot:unmute",

  CustomCommandsView = "custom_commands:view",
  CustomCommandsCreate = "custom_commands:create",
  CustomCommandsEdit = "custom_commands:edit",
  CustomCommandsDelete = "custom_commands:delete",
  CustomCommandsToggle = "custom_commands:toggle",

  DefaultCommandsView = "default_commands:view",
  DefaultCommandsEdit = "default_commands:edit",
  DefaultCommandsToggle = "default_commands:toggle",

  KeywordsView = "keywords:view",
  KeywordsCreate = "keywords:create",
  KeywordsEdit = "keywords:edit",
  KeywordsToggle = "keywords:toggle",
  KeywordsDelete = "keywords:delete",

  TimersView = "timers:view",
  TimersCreate = "timers:create",
  TimersEdit = "timers:edit",
  TimersToggle = "timers:toggle",
  TimersDelete = "timers:delete",

  PermissionsView = "permissions:view",
  PermissionsCreate = "permissions:create",
  PermissionsAssign = "permissions:assign",
  PermissionsRevoke = "permissions:revoke",
  PermissionsEdit = "permissions:edit",
  PermissionsDelete = "permissions:delete",

  ModulesView = "modules:view",
  ModulesEdit = "modules:edit",
  ModulesToggle = "modules:toggle",

  FiltersView = "filters:view",
  FiltersCreate = "filters:create",
  FiltersEdit = "filters:edit",
  FiltersDelete = "filters:delete",
  FiltersToggle = "filters:toggle",

  BanphrasesView = "banphrases:view",
  BanphrasesCreate = "banphrases:create",
  BanphrasesEdit = "banphrases:edit",
  BanphrasesToggle = "banphrases:toggle",
  BanphrasesDelete = "banphrases:delete",

  IntegrationsView = "integrations:view",
  IntegrationsEdit = "integrations:edit",
  IntegrationsToggle = "integrations:toggle",
  IntegrationsStreamlabsView = "integrations:streamlabs:view",
  IntegrationsStreamlabsEdit = "integrations:streamlabs:edit",
  IntegrationsStreamlabsToggle = "integrations:streamlabs:toggle",
  IntegrationsStreamelementsView = "integrations:streamelements:view",
  IntegrationsStreamelementsEdit = "integrations:streamelements:edit",
  IntegrationsStreamelementsToggle = "integrations:streamelements:toggle",

  WidgetsView = "widgets:view",
  WidgetsLink = "widgets:link",
  WidgetsLinkReset = "widgets:link:reset",
  WidgetsCreate = "widgets:create",
  WidgetsEdit = "widgets:edit",
  WidgetsSimulate = "widgets:simulate",
  WidgetsSimulateLive = "widgets:simulate:live",
  WidgetsDelete = "widgets:delete",

  LoyaltyView = "loyalty:view",
  LoyaltyEdit = "loyalty:edit",
  LoyaltyToggle = "loyalty:toggle",

  SongrequestView = "songrequest:view",
  SongrequestEdit = "songrequest:edit",
  SongrequestMove = "songrequest:move",
  SongrequestRemove = "songrequest:remove",
  SongrequestCreate = "songrequest:create",
}
