import * as React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {Loadable} from "web/common/components/loadable";
import {
  ParentScopesProvider,
  RolesProvider,
} from "web/dashboard/contexts";
import {UserCacheProvider} from "web/dashboard/contexts/user-cache";
import {DashboardLayout} from "../dashboard-layout";
import {DashboardLoader} from "../dashboard-loader";

export interface ReduxStateProps {
  authenticated: boolean | null;
}

export interface PublicProps {}

const BanphrasesPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "dashboard.pages.banphrases" */ "../../pages/banphrases"
    ),
  "Banphrases",
  DashboardLoader,
);

const DashboardPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "dashboard.pages.dashboard" */ "../../pages/dashboard"
    ),
  "DashboardPage",
  DashboardLoader,
);

const CommandsPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "dashboard.pages.commands" */ "../../pages/commands"
    ),
  "Commands",
  DashboardLoader,
);

const KeywordsPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "dashboard.pages.keywords" */ "../../pages/keywords"
    ),
  "Keywords",
  DashboardLoader,
);

const FiltersPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "dashboard.pages.filters" */ "../../pages/filters"
    ),
  "Filters",
  DashboardLoader,
);

const ModulesPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "dashboard.pages.modules" */ "../../pages/modules"
    ),
  "ModulesPage",
  DashboardLoader,
);

const RolesPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "dashboard.pages.roles" */ "../../pages/roles"
    ),
  "Roles",
  DashboardLoader,
);

const WIP = Loadable(
  () =>
    import(
      /* webpackChunkName: "dashboard.pages.wip" */ "../../pages/wip"
    ),
  "WIP",
  DashboardLoader,
);

type Props = ReduxStateProps & PublicProps;

export const DashboardComponent: React.FunctionComponent<Props> = (
  props: Props,
) => {
  let content = null;

  if (props.authenticated) {
    content = (
      <ParentScopesProvider>
        <RolesProvider>
          <Switch>
            <Route
              exact
              path="/:parentType/:parent/dashboard"
              component={DashboardPage}
            />
            <Route
              path="/:parentType/:parent/blocked-terms"
              component={BanphrasesPage}
            />
            <Route
              path="/:parentType/:parent/commands"
              component={CommandsPage}
            />
            <Route
              path="/:parentType/:parent/keywords"
              component={KeywordsPage}
            />
            <Route
              path="/:parentType/:parent/filters"
              component={FiltersPage}
            />
            <Route
              path="/:parentType/:parent/modules"
              component={ModulesPage}
            />
            <Route
              path="/:parentType/:parent/timers"
              component={WIP}
            />
            <Route path="/:parentType/:parent/bans" component={WIP} />
            <Route
              path="/:parentType/:parent/song-request"
              component={WIP}
            />
            <Route
              path="/:parentType/:parent/giveaways"
              component={WIP}
            />
            <Route
              path="/:parentType/:parent/widgets"
              component={WIP}
            />
            <Route
              path="/:parentType/:parent/integrations"
              component={WIP}
            />
            <Route
              path="/:parentType/:parent/roles"
              component={RolesPage}
            />
            <Route
              path="/:parentType/:parent/settings"
              component={WIP}
            />
            <Route
              render={({match}) => (
                <Redirect
                  to={`${match.path.substr(
                    0,
                    2,
                  )}/${encodeURIComponent(
                    match.params.parent,
                  )}/dashboard`}
                />
              )}
            />
          </Switch>
        </RolesProvider>
      </ParentScopesProvider>
    );
  } else if (props.authenticated === false) {
    content = (
      <Redirect
        to={`/?next=${encodeURIComponent(window.location.href)}`}
      />
    );
  }

  return (
    <DashboardLayout>
      <UserCacheProvider>{content}</UserCacheProvider>
    </DashboardLayout>
  );
};
