declare const process: {
  env: {
    NODE_ENV: string;
    REACT_GQL_BASE: string;
    REACT_TWITCH_CLIENT_ID: string;
  };
};

export enum Environment {
  Production,
  Development,
}

export class Config {
  public gqlBase = process.env.REACT_GQL_BASE;
  public twitchClientID = process.env.REACT_TWITCH_CLIENT_ID;
  public environment =
    process.env.NODE_ENV === "production"
      ? Environment.Production
      : Environment.Development;
}
