import {createModel} from "@rematch/core";
import {Filter} from "common/models";
import {apiRequest} from "core/services/api";
import {commonReducers} from "core/store/helpers";
import {ApiClientMethod} from "core/utils/api-client";

export interface FilterStore {
  error: string;
  filters: Filter[];
  hasFilters: boolean;
  loading: number;
  submitting: number;
}

const initialState: FilterStore = {
  error: "",
  filters: [],
  hasFilters: false,
  loading: 0,
  submitting: 0,
};

export const filterStore = createModel({
  state: initialState,

  reducers: {
    ...commonReducers,
    setFilters: (state: FilterStore, payload: Filter[]) => ({
      ...state,
      filters: payload,
    }),
    setHasFilters: (state: FilterStore, payload: boolean) => ({
      ...state,
      hasFilters: payload,
    }),
    pushFilter: (state: FilterStore, payload: Filter) => ({
      ...state,
      filters: state.filters.find((c) => payload.id === c.id)
        ? state.filters.map((c) =>
            c.id === payload.id ? payload : c,
          )
        : [...state.filters, payload],
    }),
    removeFilter: (state: FilterStore, payload: string) => ({
      ...state,
      filters: state.filters.filter((f) => f.id !== payload),
    }),
  },

  effects: () => ({
    getFilters(parent: string) {
      this.setError("");
      this.setFilters([]);
      this.setHasFilters(false);
      apiRequest({
        method: ApiClientMethod.GET,
        url: `v2/filters/${encodeURIComponent(parent)}`,
        onLoadStart: this.incrementLoading,
        onLoadEnd: this.decrementLoading,
        onSuccess: (body: {data: Filter[]}) => {
          this.setFilters(body?.data ?? []);
          this.setHasFilters(true);
        },
        onError: this.setError,
      });
    },

    createFilter(payload: {
      parent: string;
      filter: Filter;
      onSuccess(): void;
    }) {
      this.setError("");
      apiRequest({
        method: ApiClientMethod.POST,
        url: `v2/filters/${encodeURIComponent(payload.parent)}`,
        body: JSON.stringify(payload.filter),
        onLoadStart: this.incrementSubmitting,
        onLoadEnd: this.decrementSubmitting,
        onSuccess: (body: Filter) => {
          this.pushFilter(body);
          payload.onSuccess();
        },
        onError: this.setError,
      });
    },

    deleteFilter(payload: {
      parentId: string;
      filterId: string;
      onSuccess(): void;
    }) {
      this.setError("");
      apiRequest({
        method: ApiClientMethod.DELETE,
        url: `v2/filters/${payload.parentId}/${payload.filterId}`,
        onLoadStart: this.incrementSubmitting,
        onLoadEnd: this.decrementSubmitting,
        onSuccess: () => {
          this.removeFilter(payload.filterId);
          payload.onSuccess();
        },
        onError: this.setError,
      });
    },

    updateFilter(payload: {
      parent: string;
      filter: Filter;
      onSuccess(): void;
    }) {
      this.setError("");
      apiRequest({
        method: ApiClientMethod.PATCH,
        url: `v2/filters/${encodeURIComponent(payload.parent)}/${
          payload.filter.id
        }`,
        body: JSON.stringify(payload.filter),
        onLoadStart: this.incrementSubmitting,
        onLoadEnd: this.decrementSubmitting,
        onSuccess: (body: Filter) => {
          this.pushFilter(body);
          payload.onSuccess();
        },
        onError: this.setError,
      });
    },
  }),
});
