import {connect} from "react-redux";
import {
  ReduxStateProps,
  PublicProps,
  DashboardComponent,
} from "./component";
import {RootState} from "core/store/store";

function mapStateToProps(state: RootState): ReduxStateProps {
  return {authenticated: state.userStore.authorized};
}

export const Dashboard: React.FunctionComponent<PublicProps> = connect(
  mapStateToProps,
)(DashboardComponent);
