import {createModel} from "@rematch/core";
import {commonReducers} from "core/store/helpers";
import {apiRequest} from "core/services/api";
import {ApiClientMethod} from "core/utils/api-client";
import {HomeStreamer} from "common/models/home";

export interface HomeStore {
  streamers: HomeStreamer[];
  loadedStreamers: boolean;
  loading: number;
  error: string | null;
}

const initialState: HomeStore = {
  streamers: [],
  loadedStreamers: false,
  loading: 0,
  error: null,
};

export const homeStore = createModel({
  state: initialState,

  reducers: {
    ...commonReducers,

    setStreamers: (state: HomeStore, payload: HomeStreamer[]) => ({
      ...state,
      loadedStreamers: true,
      streamers: payload,
    }),
  },

  effects: () => ({
    getStreamers(_: unknown, state: {homeStore: HomeStore}) {
      if (state.homeStore.loadedStreamers) {
        return;
      }

      apiRequest({
        method: ApiClientMethod.GET,
        url: "api/v2/cms/home-streamers",
        onLoadStart: this.incrementLoading,
        onLoadEnd: this.incrementLoading,
        onError: this.setError,
        onSuccess: (streamers: HomeStreamer[]) =>
          this.setStreamers(streamers),
      });
    },
  }),
});
