import {createModel} from "@rematch/core";
import {commonReducers} from "core/store/helpers";
import {apiRequest} from "core/services/api";
import {ApiClientMethod} from "core/utils/api-client";
import {
  CustomCommand,
  DefaultCommand,
  CustomCommandBody,
  ModulesEnabledStatus,
} from "common/models";
import {app} from "core/app";

export interface CommandsStore {
  customCommands: CustomCommand[];
  defaultCommands: DefaultCommand[];
  loading: number;
  submitting: number;
  loadedCustom: boolean;
  loadedDefault: boolean;
  error: string;
  modalError: string;
  modulesStatus: ModulesEnabledStatus | null;
}

const initialState: CommandsStore = {
  customCommands: [],
  defaultCommands: [],
  loading: 0,
  submitting: 0,
  loadedCustom: false,
  loadedDefault: false,
  error: "",
  modalError: "",
  modulesStatus: null,
};

export const commandsStore = createModel({
  state: initialState,

  reducers: {
    ...commonReducers,
    setCustomCommands: (
      state: CommandsStore,
      payload: {data: CustomCommand[]},
    ) => ({
      ...state,
      customCommands: payload.data,
      loadedCustom: true,
    }),
    setDefaultCommands: (
      state: CommandsStore,
      payload: {data: DefaultCommand[]},
    ) => ({
      ...state,
      defaultCommands: payload.data,
      loadedDefault: true,
    }),
    setModulesStatus: (
      state: CommandsStore,
      payload: ModulesEnabledStatus,
    ) => ({
      ...state,
      modulesStatus: payload,
    }),

    toggleCustomCommand: (state: CommandsStore, payload: string) => ({
      ...state,
      customCommands: state.customCommands.map((c) =>
        c.id === payload ? {...c, enabled: !c.enabled} : c,
      ),
    }),

    toggleDefaultCommand: (
      state: CommandsStore,
      payload: string,
    ) => ({
      ...state,
      defaultCommands: state.defaultCommands.map((c) =>
        c.id === payload ? {...c, enabled: !c.enabled} : c,
      ),
    }),

    removeCustomCommand: (state: CommandsStore, payload: string) => ({
      ...state,
      customCommands: state.customCommands.filter(
        (c) => c.id !== payload,
      ),
    }),

    pushCustomCommand: (
      state: CommandsStore,
      payload: CustomCommand,
    ) =>
      state.loadedCustom
        ? {
            ...state,
            customCommands:
              state.loadedCustom &&
              state.customCommands.find((c) => payload.id === c.id)
                ? state.customCommands.map((c) =>
                    c.id === payload.id ? payload : c,
                  )
                : [...state.customCommands, payload],
          }
        : state,

    pushDefaultCommand: (
      state: CommandsStore,
      payload: DefaultCommand,
    ) =>
      state.loadedDefault
        ? {
            ...state,
            defaultCommands:
              state.loadedDefault &&
              state.defaultCommands.find((c) => payload.id === c.id)
                ? state.defaultCommands.map((c) =>
                    c.id === payload.id ? payload : c,
                  )
                : [...state.defaultCommands, payload],
          }
        : state,
  },

  effects: () => ({
    getCustom(parent: string) {
      this.setError("");
      apiRequest({
        method: ApiClientMethod.GET,
        url: `v2/commands/${encodeURIComponent(parent)}/custom`,
        onLoadStart: this.incrementLoading,
        onLoadEnd: this.decrementLoading,
        onSuccess: (commands) =>
          requestAnimationFrame(() =>
            this.setCustomCommands(commands),
          ),
        onError: this.setError,
      });
    },

    getDefault(parent: string) {
      this.setError("");
      apiRequest({
        method: ApiClientMethod.GET,
        url: `v2/commands/${encodeURIComponent(parent)}/default`,
        onLoadStart: this.incrementLoading,
        onLoadEnd: this.decrementLoading,
        onSuccess: (commands) => this.setDefaultCommands(commands),
        onError: this.setError,
      });
    },

    createCustom(payload: {
      parent: string;
      body: CustomCommandBody;
      done: () => void;
    }) {
      this.setModalError("");
      apiRequest({
        method: ApiClientMethod.POST,
        body: JSON.stringify(payload.body),
        url: `v2/commands/${encodeURIComponent(
          payload.parent,
        )}/custom`,
        onLoadStart: this.incrementSubmitting,
        onLoadEnd: this.decrementSubmitting,
        onSuccess: (command: CustomCommand) => {
          payload.done();
          this.pushCustomCommand(command);
          app.snackbarUtil.enqueueSnackbar(
            "Successfully created command!",
          );
        },
        onError: this.setModalError,
      });
    },

    updateCustom(payload: {
      parent: string;
      commandId: string;
      body: CustomCommandBody;
      done: () => void;
    }) {
      this.setModalError("");
      apiRequest({
        method: ApiClientMethod.PATCH,
        body: JSON.stringify(payload.body),
        url: `v2/commands/${encodeURIComponent(
          payload.parent,
        )}/custom/${encodeURIComponent(payload.commandId)}`,
        onLoadStart: this.incrementSubmitting,
        onLoadEnd: this.decrementSubmitting,
        onSuccess: (command: CustomCommand) => {
          payload.done();
          this.pushCustomCommand(command);
          app.snackbarUtil.enqueueSnackbar(
            "Successfully updated command!",
          );
        },
        onError: this.setModalError,
      });
    },

    updateDefault(payload: {
      parent: string;
      commandId: string;
      body: DefaultCommand;
      done: () => void;
    }) {
      this.setModalError("");
      apiRequest({
        method: ApiClientMethod.PATCH,
        body: JSON.stringify(payload.body),
        url: `v2/commands/${encodeURIComponent(
          payload.parent,
        )}/default/${encodeURIComponent(payload.commandId)}`,
        onLoadStart: this.incrementSubmitting,
        onLoadEnd: this.decrementSubmitting,
        onSuccess: (command: CustomCommand) => {
          payload.done();
          this.pushDefaultCommand(command);
          app.snackbarUtil.enqueueSnackbar(
            "Successfully updated command!",
          );
        },
        onError: this.setModalError,
      });
    },

    toggleCustom(payload: {
      parent: string;
      command: string;
      enabled: boolean;
    }) {
      this.toggleCustomCommand(payload.command);
      apiRequest({
        method: ApiClientMethod.PATCH,
        body: JSON.stringify({enabled: payload.enabled}),
        url: `v2/commands/${encodeURIComponent(
          payload.parent,
        )}/custom/${encodeURIComponent(payload.command)}/toggle`,
        onError: (error) => {
          this.toggleCustomCommand(payload.command);
          app.snackbarUtil.enqueueSnackbar(error.toString());
        },
      });
    },

    toggleDefault(payload: {
      parent: string;
      command: string;
      enabled: boolean;
    }) {
      this.toggleDefaultCommand(payload.command);
      apiRequest({
        method: ApiClientMethod.PATCH,
        body: JSON.stringify({enabled: payload.enabled}),
        url: `v2/commands/${encodeURIComponent(
          payload.parent,
        )}/default/${encodeURIComponent(payload.command)}/toggle`,
        onError: (error) => {
          this.toggleDefaultCommand(payload.command);
          app.snackbarUtil.enqueueSnackbar(error.toString());
        },
      });
    },

    deleteCustom(payload: {parent: string; command: string}) {
      this.setModalError("");
      apiRequest({
        method: ApiClientMethod.DELETE,
        url: `v2/commands/${encodeURIComponent(
          payload.parent,
        )}/custom/${encodeURIComponent(payload.command)}`,
        onSuccess: () => {
          this.removeCustomCommand(payload.command);
          app.snackbarUtil.enqueueSnackbar(
            "Successfully deleted command!",
          );
        },
        onError: this.setModalError,
        onLoadStart: this.incrementSubmitting,
        onLoadEnd: this.decrementSubmitting,
      });
    },

    getModulesStatus(parent: string) {
      apiRequest({
        method: ApiClientMethod.GET,
        url: `v2/modules/${encodeURIComponent(
          parent,
        )}/enabled-status`,
        onLoadStart: this.incrementLoading,
        onLoadEnd: this.decrementLoading,
        onSuccess: this.setModulesStatus,
      });
    },
  }),
});
